import * as React from "react";
import "../Styles/style.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { Avatar } from "@mui/material";
// import LoadingModal from "../../modals/loading-modal";
import { AuthContext } from "../../context/AuthContext";
import LoadingModal from "../modals/loading-modal";
import { v4 as uuidv4 } from 'uuid';import Form from "react-bootstrap/Form";


export default function OfflineOrders() {
  const { user } = React.useContext(AuthContext);

// const fetchedOrdersIndex = JSON.parse(localStorage.getItem(`inspectionOrdersIndex`))


  const [orderType, setOrderType] = React.useState(1);
  const [inspectionOrders, setInspectionOrders] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderList, setOrderList] = useState([]);

  const fetchInspectionOrders = async () => {
  const orders = localStorage.getItem("inspectionOrdersList")
  setInspectionOrders(JSON.parse(orders))
  };

  useEffect(() => {
    fetchInspectionOrders();
  }, []);

  const history = useHistory();  

  const handleButtonClick = async (Id,name,phone,index) => {
    const newToken = uuidv4().replace(/-/g, "").slice(0, 12);
    history.push(`/inspector-form?token=${newToken}&&inspection=${Id}`);

localStorage.setItem(`${newToken}_userData`,JSON.stringify({name,phone}))

// fetchedOrdersIndex.splice(index,1)

// localStorage.setItem("inspectionOrdersIndex",JSON.stringify(fetchedOrdersIndex))

  };
  
  const openWhatsApp = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber ? `92${phoneNumber.slice(1)}` : "";
    const whatsappUrl = `http://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodeURIComponent(
      "Hi,"
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handlePhone = (number) => {
    const phoneNumber = number;
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />
      <div className="MyAds_main py-5">
        <div className="container MyAds_body">
          <div className="row align-items-center pb-5">
            <div className="col-lg-6 col-12">
              <h3 className="pb-2 fw-700 text-center text-uppercase  fw-title">
               Offline Inspection Orders
              </h3>
            </div>
            <div className="col-lg-6 col-12">
              <div className="d-flex justify-content-center justify-content-md-end align-content-center text-center text-sm-center text-lg-start">
                <div>
                  <label for="vehicleMake" className="form-label fw-600 me-2">
                    Order Type:
                  </label>
                  <select
                    className="form-select d-inline w-auto biddingDropDwon fw-500"
                    id="vehicleMake"
                    aria-label="Default select example"
                    required
                    value={orderType}
                    onChange={(e) => setOrderType(e.target.value)}
                  >
                    <option value="1">Bidding Inspections</option>
                    <option value="0">Normal Inspection</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          
 

          <div className="row">
            {inspectionOrders &&
              inspectionOrders?.map((order, index) => {
                return (
                  <div
                    key={order.inspection_id}
                    className="card col-lg-6 px-3 py-2 mb-2"
                  >
                    <h4
                      className="d-flex justify-content-end "
                      style={{ fontSize: "10px" }}
                    >
                      <i className="fa-regular fa-clock me-1"></i> {order?.inspection_slot}
                    </h4>
                    <div className="row h-100">
                      <div className="col-10 d-flex flex-column justify-content-between align-items-center justify-content-between">
                        <div className="d-flex w-100 justify-content-start">
                          <section className="col-6 inspection_Bio">
                            <div className="d-flex gap-2 justify-content-start">
                              <Avatar src="/broken-image.jpg" />

                              <div className="inspection_Bio d-flex flex-column gap-0 justify-content-center">
                                <h4 className="m-0">{order.full_name}</h4>
                                <p className="m-0">{order.city_name}</p>
                              </div>
                            </div>
                          </section>

                          <section className=" inspection_Order">
                            <p className="fw-bold">
                              {order.make_name} {order.model_name} {order.yearName || order.year_name}
                            </p>
                          </section>
                        </div>

                        <div
                          className="d-flex justify-content-start w-100 align-items-center gap-2 my-1"
                          style={{ fontSize: "14px" }}
                        >
                          <p className="m-0">Address </p>{" "}
                          <p className=" fw-bold m-0"> {order.address}</p>
                        </div>
                      </div>

                      <section className="col-2">
                        <div className="d-flex flex-column align-items-end h-100 gap-1">
                          <button
                            // href={`tel:${order.phone}`}
                            onClick={() => handlePhone(order.phone)}
                            className="d-flex justify-content-center align-items-center inpection_order_btn bg-primary rounded-2 fs-5 text-white border-0 h-100"
                          >
                            <i className="fa-solid fa-phone"></i>
                          </button>
                          <button
                            onClick={()=>openWhatsApp(order.phone)}
                            className="d-flex justify-content-center align-items-center inpection_order_btn bg-success text-white rounded-2 fs-5 border-0 h-100"
                          >
                            <i className="fa-brands fa-whatsapp"></i>
                          </button>
                        </div>
                      </section>
                    </div>

                    <Button
                      onClick={() =>
                        handleButtonClick(`${order?.inspection_id}`,order?.full_name,order?.phone,index)
                      }
                      variant="contained"
                      className="py-1 mt-2 px-4 color-white fw-700 bgSecondary  text-capitalize"
                    >
                      Start
                    </Button>

                  </div>
                );
              })}
            {inspectionOrders?.length <= 0 && (
              <p className="fs-4 text-center mt-4 text-danger fw-bold">
                No Inspection Orders Available
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
