import React, { useEffect } from "react";
import InspectionReport from "../components/vehicleInspection/inspectionReport";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";

export default function InspectorReport() {
  const { user } = React.useContext(AuthContext);
  const history = useHistory()
  useEffect(() => {
    document.title = "Car Inspection Report | FameWheels";

    // if (!user) {
    //   history.push("/")
    // }

  }, []);
  return (
    <>
      <InspectionReport />
    </>
  );
}
