import "../../Styles/style.css";
import Button from "@mui/material/Button";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import baseUrl from "../../../api/ipconfig";
import InputMask from "react-input-mask";
import ImageModal from "../imagePreviewModal";
import LoadingModal from "../../modals/loading-modal";
import PreLoader from "../../loader";
import { useHistory } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

const Step1 = ({ onSuccess, APIResponse, user, userData,mileage,setMileage,engineNo,setEngineNo,chassisNo,setChassisNo,registeredNo,setRegisteredNo,confirmRegisteredNo,setConfirmRegisteredNo,driveType,setDriveType,engineType,setEngineType,cngInstall,setCngInstall,color,setColor,engineCapacity,setEngineCapacity,transmissionType,setTransmissionType,registeredCity,setRegisteredCity,registeredYear,setRegisteredYear }) => {
  const token = localStorage.getItem("token");
  const history = useHistory();

  const colorsData = {
    colors: [
      {
        name: "white",
        code: "#FFFFFF",
      },
      {
        name: "Silver",
        code: "#C0C0C0",
      },
      {
        name: "black",
        code: "#000000",
      },
      {
        name: "Grey",
        code: "#888888",
      },
      {
        name: "blue",
        code: "#000053",
      },
      {
        name: "green",
        code: "#0B780B",
      },
      {
        name: "red",
        code: "#FF0000",
      },
      {
        name: "gold",
        code: "#FFD700",
      },
      {
        name: "maroon",
        code: "#800000",
      },
      {
        name: "beige",
        code: "#F5F5DC",
      },
      {
        name: "pink",
        code: "#DA55AD",
      },
      {
        name: "brown",
        code: "#966938",
      },
      {
        name: "burgundy",
        code: "#6C1C1F",
      },
      {
        name: "yellow",
        code: "#FFFF00",
      },
      {
        name: "bronze",
        code: "#574327",
      },
      {
        name: "purple",
        code: "#57105C",
      },
      {
        name: "Turqouise",
        code: "#22EEE3",
      },
      {
        name: "orange",
        code: "#FE4F09",
      },
      {
        name: "indigo",
        code: "#3A125E",
      },
      {
        name: "magneta",
        code: "#65183A",
      },
      {
        name: "other",
        code: "#FFFFFF",
      },
    ],
  };
  const [validRegistration, setValidRegistration] = useState(true);

  // const [registeredNo, setRegisteredNo] = useState(null);
  // const [confirmRegisteredNo, setConfirmRegisteredNo] = useState(null);
  const [initial_id, setInitial_id] = useState(null);

  const [pending,setPending] = useState(false)
  const [pendingData,setPendingData] = useState('')
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const arr = new Uint8Array(raw.length);

    for (let i = 0; i < raw.length; i++) {
      arr[i] = raw.charCodeAt(i);
    }

    return new Blob([arr], { type: contentType });
  };

  const formRef = useRef(null);
  const btnRef = useRef(null);
  const [cities, setCities] = useState(null);
  const [years, setYears] = useState(null);
  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState(null);
  const [userDataInfo, setUserDataInfo] = useState({});

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  useEffect(() => {
    const fetchId = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/ganerateinspection`,
          { userId: user?.id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setInitialData(response?.data);
        setLoading(false);
        //console.log("ganerateinspection", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchId();
  }, [token, user?.id]);

  useEffect(() => {}, []);
  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };
  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    const fetchDraftPending = async () => {
      try {
        const response = await axios.get(`${baseUrl}/inspectionstepdetails`, {
          params: {
            iinitial_token: StepToken,
            inspectionstep_id: 1,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        //console.log(response?.data?.initial, "draft step 1");
        const formData = response?.data?.initial;
        setInitial_id(response?.data?.initial?.iinitial_id);

        populateFormWithResponse(formData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  const handleImageChange = (e, fieldName) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = async () => {
      setImages((prevImages) => ({
        ...prevImages,
        [fieldName]: reader.result,
      }));

      const params = {
        data: new FormData(),
      };
      params.data.append("iinitial_token", initialData?.iinitial_token);
      params.data.append("userId", user?.id);
      params.data.append("inspectionstep_name", "iinitial");
      params.data.append("inspectionstep_id", 1);
      Object.entries({ [fieldName]: reader.result }).forEach(
        ([fieldName, file]) => {
          params.data.append("image_file", dataURLtoBlob(file));
          params.data.append("image_name", fieldName);
        }
      );
      const response = await axios.post(
        `${baseUrl}/uploadinspectionimage`,
        params?.data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      //console.log("uploadinspectionimage", response.data);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleModalOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const now = new Date();
  // Apply the timezone offset to the current date to get Pakistan's date
  const pakistanTimezoneOffset = 5 * 60 + 0;
  const pakistanDate = new Date(
    now.getTime() + pakistanTimezoneOffset * 60 * 1000
  );
  const formattedDate = `${pakistanDate.getFullYear()}-${(
    pakistanDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${pakistanDate.getDate().toString().padStart(2, "0")}`;




const getInpectionId = async ()=>{
  const url = window.location.search;
  const inspectionId = extractTokenFromUrl(url, "inspection");
  try {
    const response = await axios.get(`${baseUrl}/inspectionrequestdetail`,{
      params:{
        inspection_id:inspectionId
      }
    })

    //console.log("user dta",response?.data)
    setUserDataInfo(response?.data)
    setName(response?.data?.vehicledetail?.full_name)
    setPhone(response?.data?.vehicledetail?.phone)
  } catch (error) {
    console.error(
      error
    )
  }
}



  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(`${baseUrl}/cities`);

        // //console.log("All Cities", response.data);
        setCities(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCity();
  }, []);

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getModelYear`);

        // //console.log("All Cities", response.data);
        //console.log("years ======== > ", response.data);
        setYears(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchYears();
    getInpectionId()
  }, []);

  //console.log("user", userData);

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      //console.log("initial_id", initial_id);
      const url = window.location.search;
      const StepToken = extractTokenFromUrl(url, "token");
      const inspectionId = extractTokenFromUrl(url, "inspection");
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("iinitial_token", initialData?.iinitial_token);
          params.data.append("user_id", userData?.id);
          params.data.append("iinitial_date", formattedDate);
          // params.data.append("is_edit", StepToken ? 1 : 0);

          params.data.append("iinitial_id", initial_id ? initial_id : formId);
          params.data.append("inspection_id", inspectionId);

          // Object.entries(images).forEach(([fieldName, file]) => {
          //   params.data.append(fieldName, dataURLtoBlob(file));
          // });
          try {
          
            const response = await axios.post(
              `${baseUrl}/saveinitialinspection`,
              params?.data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (response?.data) {
              setSuccessOpen(true)
            setSuccess("Step 1 Completed")
            }

            APIResponse(response?.data);
            history.push(`inspector-online-form?token=${initialData?.iinitial_token}&inspection=${inspectionId}`);
            if (response) {
              setIsSubmitting(false);
            }
            formRef.current.hasUnsavedChanges = false;
          } catch (e) {
            console.error(e);
            setErrOpen(true)
            setError(`Step 1 Submit Failed!`)
            setIsSubmitting(false);
            return;
          } finally {
            btnRef.current?.setLoader(false);
          }
        }
        onSuccess();
      };

      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true)
          setPendingData("Completing Step 1")
          CreateUser();
        } catch (e) {
          console.error(e);
          setIsSubmitting(false);
        } finally {
          setPending(false)
          btnRef.current?.setLoader(false);
          // setIsSubmitting(false);
        }
        btnRef.current?.setLoader(false);
      }
    },
    [
      APIResponse,
      formattedDate,
      initialData?.iinitial_token,
      onSuccess,
      token,
      user?.id,
    ]
  );



  const handleRegistrationNumber = (e) => {
    const inputValue = e.target.value;
    const registrationRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{1,3}-\d{1,4}$/;
  
    setRegisteredNo(inputValue);
    if (inputValue.length <= 8 && registrationRegex.test(inputValue)) {
      //console.log("valid registration number");
      setValidRegistration(true)
  
    } else {
      //console.log("Invalid registration number");
      setValidRegistration(false)
  
    }
  };

  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
          <LoadingModal
            open={isSubmitting}
            onClose={() => setIsSubmitting(false)}
          />
          <div className="container my-md-5">
            <div className="postInform p-5">
              <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
                {/* <div className="text-center pb-4  ">
                  <h4>Customer Details</h4>
                </div> */}
                <div className="row">
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize"
                    >
                      Customer Name
                    </label>
                    <input
                      name="iinitial_sellername"
                      type="text"
                      className="form-control btntextSize"
                      id="post-title"
                      aria-describedby="titleHelp"
                      placeholder="Seller Name"
                      value={name}
                      onChange={(e)=>setName(e.target.value)}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Customer Phone Number
                    </label>
                    <InputMask
                      mask="99999999999"
                      maskChar={null}
                      type="text"
                      name="iinitial_sellerno"
                      className="form-control btntextSize"
                      id="post-title"
                      placeholder="Phone No. (03xxxxxxxxx)"
                      value={phone}
                      onChange={(e)=>setPhone(e.target.value)}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleKM"
                      className="form-label text-black  btntextSize "
                    >
                      Mileage * (km)
                    </label>
                    <div className="input-group">
                      <input
                        name="iinitial_mileage"
                        type="number"
                        className="form-control btntextSize"
                        id="vehicleKM"
                        placeholder="Mileage"
                        min="0"
                        required
                        value={mileage}
                        onChange={(e) => setMileage(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize"
                    >
                      Engine No.
                    </label>
                    <div className="input-group">
                      <input
                        name="iinitial_engineno"
                        type="text"
                        className="form-control btntextSize"
                        id="post-title"
                        aria-describedby="titleHelp"
                        placeholder="Engine No."
                        value={engineNo}
                        onChange={(e) => setEngineNo(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Chassis No.
                    </label>
                    <div className="input-group">
                      <input
                        name="iinitial_chassisno"
                        type="text"
                        className="form-control btntextSize"
                        id="post-title"
                        aria-describedby="titleHelp"
                        required
                        placeholder="Chassis No."
                        value={chassisNo}
                        onChange={(e) => setChassisNo(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize"
                    >
                      Registered No.
                    </label>
                    <input
                      name="iinitial_registeredno"
                      type="text"
                      className="form-control btntextSize text-uppercase"
                      id="post-title"
                      aria-describedby="titleHelp"
                      required
                      maxLength={8}
                      placeholder="abc-1234"
                      value={registeredNo}
                      onChange={handleRegistrationNumber}
                    />


{!validRegistration && (
  <p
                        className="color-secondary mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        Should have Format of ABC-1234
                      </p>
) 

}

                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Confirm Registered No.
                    </label>
                    <input
                      type="text"
                      className="form-control btntextSize text-uppercase"
                      id="post-title"
                      aria-describedby="titleHelp"
                      required
                      placeholder="abc-1234"
                      value={confirmRegisteredNo}
                      onChange={(e) => setConfirmRegisteredNo(e.target.value)}
                    />
                    {registeredNo !== confirmRegisteredNo && (
                      <p
                        className="color-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        Registered No and Confirm Registered No Should be same
                      </p>
                    )}
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleDriveType"
                      className="form-label text-black  btntextSize "
                    >
                      Drive Type
                    </label>
                    <select
                      name="iinitial_drivetype"
                      className="form-select btntextSize"
                      id="vehicleDriveType"
                      aria-label="Default select example"
                      required
                      value={driveType}
                        onChange={(e) => setDriveType(e.target.value)}
                    >
                      <option selected value="">
                        Select Drive Type
                      </option>
                      <option value="2WD">2WD</option>
                      <option value="4WD">4WD</option>
                      <option value="AWD">AWD</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleEngineType"
                      className="form-label text-black  btntextSize "
                    >
                      Engine Type
                    </label>
                    <select
                      name="iinitial_enginetype"
                      className="form-select btntextSize"
                      id="vehicleEngineType"
                      aria-label="Default select example"
                      required
                      value={engineType}
                      onChange={(e) => setEngineType(e.target.value)}
                    >
                      <option selected value="">
                        Select Engine Type
                      </option>
                      <option value="Petrol">Petrol</option>
                      <option value="Diesel">Diesel</option>
                      <option value="Hybrid">Hybrid</option>
                      <option value="Electric">Electric</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleCNGInstall"
                      className="form-label text-black  btntextSize "
                    >
                      CNG Install
                    </label>
                    <select
                      name="iinitial_cnginstall"
                      className="form-select btntextSize"
                      id="vehicleCNGInstall"
                      aria-label="Default select example"
                      required
                      value={cngInstall}
                      onChange={(e) => setCngInstall(e.target.value)}
                    >
                      <option selected value="">
                        Select CNG Install
                      </option>
                      <option value="N/A">N/A</option>
                      <option value="Yes">Yes</option>
                      <option value="CNG Removed">CNG Removed</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="iinitial_color"
                      className="form-label text-black  btntextSize "
                    >
                      Exterior Colour
                    </label>

                    <select
                      name="iinitial_color"
                      className="form-select btntextSize"
                      id="iinitial_color"
                      aria-label="Default select example"
                      required
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    >
                      <option selected value="">
                        Select Color
                      </option>
                      {colorsData.colors?.map((color) => (
                        <option value={color.name}>{color.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Engine Capacity
                    </label>
                    <input
                      name="iinitial_enginecapacity"
                      type="number"
                      className="form-control btntextSize"
                      id="post-title"
                      aria-describedby="titleHelp"
                      required
                      placeholder="Engine Capacity"
                      value={engineCapacity}
                      onChange={(e) => setEngineCapacity(e.target.value)}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleTransmission"
                      className="form-label text-black  btntextSize"
                    >
                      Transmission Type
                    </label>
                    <select
                      name="iinitial_transmission"
                      className="form-select btntextSize"
                      id="vehicleTransmission"
                      aria-label="Default select example"
                      required
                      value={transmissionType}
                      onChange={(e) => setTransmissionType(e.target.value)}
                    >
                      <option selected value="">
                        Select Transmission
                      </option>
                      <option value="Automatic">Automatic</option>
                      <option value="Manual">Manual</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Registered City
                    </label>
                    <select
                      name="city_id"
                      className="form-select btntextSize"
                      id="post-city"
                      aria-label="Default select example"
                      required
                      value={registeredCity}
                      onChange={(e) => setRegisteredCity(e.target.value)}
                    >
                      <option value="">Select City</option>
                      {cities &&
                        cities.map((item) => (
                          <option key={item?.cityID} value={item?.cityID}>
                            {item?.cityName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="post-year"
                      className="form-label text-black  btntextSize "
                    >
                      Registered Year
                    </label>
                    <select
                      name="year_id"
                      className="form-select btntextSize"
                      id="post-year"
                      aria-label="Default select example"
                      required
                      value={registeredYear}
                      onChange={(e) => setRegisteredYear(e.target.value)}
                    >
                      <option value="">Select Year</option>
                      {years &&
                        years.map((item) => (
                          <option key={item?.yearId} value={item?.yearId}>
                            {item?.year}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-12 mb-3">
                    <label
                      for="iinitial_comment"
                      className="form-label text-black  btntextSize "
                    >
                      Comment (Optional)
                    </label>

                    <Form.Control
                      as="textarea"
                      name="iinitial_comment"
                      placeholder="Leave a comment here"
                      style={{ height: "130px" }}
                      className="btntextSize"
                    />
                  </div>

                  <div className="text-center pt-2 pb-4">
                    <Button
                      type="submit"
                      className=" px-5 bgSecondary color-white"
                      variant="contained"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Step1;
