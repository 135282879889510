import React from "react";
import "../Styles/style.css";
import loader from "../../images/loader.gif";
import logo from "../../images/fame-wheels-logo.png";

const PreLoader = () => {
  return (
    <div>
      <div className="preloaderMain">
        <img className="loaderGif" src={loader} alt="" srcSet="" />
        <img className="loaderLogo" src={logo} alt="" srcSet="" />
      </div>
    </div>
  );
};

export default PreLoader;
