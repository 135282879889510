import * as React from "react";
import "../Styles/style.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Confirm from "../../images/blue-tick-success.png";

const InspectionSuccess = ({ open, onClose }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="md-modal position-relative p-3 p-md-4">
          <div className="modalBody_area successBox  px-2 text-center ">
            <img src={Confirm} className="mb-4" alt="confirm" srcSet="" />
            <h4 className="pb-2 fs-3 ">Inspection Successful</h4>
            <p>Redirecting.. in 3 seconds</p>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InspectionSuccess;
