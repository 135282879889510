import React, { useRef, useEffect, useState } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import ImageModal from "../imagePreviewModal";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import ImageIcon from "@mui/icons-material/Image";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import { Form } from "react-bootstrap";
import baseUrl, { baseStepsUrl } from "../../../api/ipconfig";
import InnerLoader from "../../loader/innerLoader";
import imageCompression from "browser-image-compression";
import s3 from "../../../config/s3Config";

const Step3 = ({ onSuccess, previouse, user, userData, step5, setStep5 }) => {
  const token = localStorage.getItem("token");

  const formRef = useRef(null);
  const btnRef = useRef(null);

  const [uploadingImages, setUploadingImages] = useState([]);
  const [uploadingImagesIndex, setUploadingImagesIndex] = useState([]);

  const [isAc, setIsAc] = useState("No");
  const [imagePart, setImagePart] = useState(null);

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [imagePath, setImagePath] = useState(null);

  const [prevImg, setPrevImg] = useState(null);
  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 5);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const getImages = async (fieldName) => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    setImageLoading(true);
    setImagePart(fieldName);
    try {
      // Define the prefix based on the folder structure
      const prefix = `public/inspection/${StepToken}/${fieldName}/`;

      // List objects in the specified folder
      const params = {
        Bucket: "famewheels", // Replace with your bucket name
        Prefix: prefix,
      };

      const response = await s3.listObjectsV2(params).promise();

      // Construct image URLs from the S3 objects
      const imageUrls = response.Contents.map((item) => {
        return item;
      });

      // Update your state with the retrieved images
      setImages((prevImages) => ({
        ...prevImages,
        [fieldName]: imageUrls,
      }));

      setGalleryImages(imageUrls);

      // Optionally, if you want to store the image path separately
      setImagePath(prefix);
    } catch (error) {
      console.error("Error fetching images from S3:", error);
    } finally {
      setImageLoading(false);
    }
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    if (files.length > 0) {
      try {
        // Set pending status
        setUploadingImages([...uploadingImages, fieldName]);
        setUploadingImagesIndex([...uploadingImagesIndex, fieldName]);
        setPendingData("Image uploading");

        const uploadPromises = files.map(async (file) => {
          const options = {
            maxSizeMB: 0.06,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);

          const compressedImageFile = new File([compressedFile], file.name, {
            type: file.type,
          });

          // Dynamic folder creation
          // const folderName = StepToken;
          // const childFolderName = fieldName;
          const s3Key = `public/inspection/${StepToken}/${fieldName}/${compressedImageFile.name}`;

          const params = {
            Bucket: "famewheels",
            Key: s3Key,
            Body: compressedImageFile,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: compressedImageFile.type,
          };

          const response = await s3.upload(params).promise();

          const url = response.Key;

          const ImageName = url.substring(url.lastIndexOf("/") + 1);

          const formData = new FormData();
          formData.append("image_name", fieldName);
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 5);
          formData.append(`file`, ImageName);

          const responseApi = await axios.post(
            `${baseUrl}/saveinspectionimage`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Call getImages for each uploaded image (assuming getImages updates the UI)
          await getImages(fieldName);

          // Update the uploadingImages state after successful upload
          setUploadingImages((prevUploadImages) =>
            prevUploadImages.filter((item) => item !== fieldName)
          );
          setUploadingImagesIndex((prevUploadImages) =>
            prevUploadImages.filter((item) => item !== fieldName)
          );

          return response;
        });

        // Wait for all uploadPromises to resolve
        await Promise.all(uploadPromises);

        // Show success message
        setSuccessOpen(true);
        setSuccess("Images uploaded successfully");
      } catch (error) {
        console.error("Error uploading images:", error);
        setErrOpen(true);
        setError("Couldn't upload image, try again");
        setUploadingImages([]);
        setUploadingImagesIndex([]);
      } finally {
        // Reset pending status
        setPending(false);
      }
    }
  };

  const handleDeleteImage = async (ind, key) => {
    try {
      setPending(true);
      setPendingData("Deleting Image");

      // Debug: Log the key to ensure it's being passed correctly
      console.log("Deleting image with key:", key);

      const fieldName = key.split("/")[3];

      // Validate that the key is provided
      if (!key) {
        throw new Error("Image key is required for deletion");
      }

      // S3 deletion
      await s3
        .deleteObject({
          Bucket: "famewheels", // Replace with your bucket name
          Key: key, // Use the key provided as an argument
        })
        .promise();

      // Update state
      const updatedGalleryImages = [...galleryImages];
      updatedGalleryImages.splice(ind, 1); // Remove the deleted image from the array
      setGalleryImages(updatedGalleryImages);

      await getImages(fieldName);

      setSuccessOpen(true);
      setSuccess("Image Deleted Successfully");
    } catch (error) {
      console.error("Delete image error:", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      setPending(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const arr = new Uint8Array(raw.length);

    for (let i = 0; i < raw.length; i++) {
      arr[i] = raw.charCodeAt(i);
    }

    return new Blob([arr], { type: contentType });
  };

  const handleImageGallery = (images) => {
    setOpenImageGallery(true);
  };

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);

      const url = window.location.search;
      const StepToken = extractTokenFromUrl(url, "token");
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("user_id", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
          // params.data.append("is_edit", 0);

          // Object.entries(images).forEach(([fieldName, file]) => {
          //   params.data.append(fieldName, dataURLtoBlob(file));
          // });
          try {
            const response = await axios.post(
              `${baseUrl}/savesuspensioninspection`,
              params?.data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response?.data) {
              setSuccessOpen(true);
              setSuccess("Step 5 Completed");
            }
            //console.log("====================================");
            //console.log(response);
            setIsSubmitting(false);
            //console.log("====================================");
            formRef.current.hasUnsavedChanges = false;
          } catch (e) {
            console.error(e);
            setErrOpen(true);
            setError(`Step 5 Submit Failed!`);
            setIsSubmitting(false);
            return;
          } finally {
            btnRef.current?.setLoader(false);
          }
        }
        onSuccess();
      };

      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 5");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [onSuccess, previouse?.iinitial_token, token, user?.id]
  );

  const getImageByValue = (value) => {
    // Find the item with the specified value
    const item = uploadingImages.find((item) => item === value);

    //console.log("item image ===== >",item)

    // Return the found item
    return item;
  };

  const hidePendingImage = (imageName) => {
    const updatedImages = uploadingImagesIndex.filter(
      (item) => item !== imageName
    );
    // Update the state with the new array
    setUploadingImagesIndex(updatedImages);
  };

  const updateVehicleState = (field, value) => {
    setStep5((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        autoHideDuration={15000}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      {uploadingImagesIndex?.map((img, index) => (
        <Snackbar
          key={index}
          open={true}
          // onClose={() => hidePendingImage(img)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ marginTop: `${index * 60}px` }}
        >
          <Alert
            onClose={() => hidePendingImage(img)}
            severity="info"
            style={{ width: "65%" }}
          >
            {`${pendingData}`}
          </Alert>
        </Snackbar>
      ))}

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row" key={ind}>
                  <div className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage">
                    <img
                      src={`https://famewheels.s3.eu-north-1.amazonaws.com/${img.Key}`}
                      alt={img.Key}
                      className="object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img.Key)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_engageoilleakage"
                    className="form-label btntextSize text-black"
                  >
                    Engine Oil Leakage
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_engageoilleakage"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.engineOilLeakage}
                      onChange={(e) =>
                        updateVehicleState("engineOilLeakage", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="No Leakage">No Leakage</option>
                      <option value="Leakage">Leakage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_engineoilleakageimage &&
                      images.iss_engineoilleakageimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iss_engineoilleakageimage
                            );
                            setImageId("iss_engineoilleakageimage");
                            getImages("iss_engineoilleakageimage");
                          }}
                        >
                          {images.iss_engineoilleakageimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_engineoilleakageimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_engineoilleakageimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_engineoilleakageimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_engineoilleakageimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_engineoilleakageimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_transmissionoilleakage"
                    className="form-label btntextSize text-black"
                  >
                    Transmission Oil Leakage
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_transmissionoilleakage"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.transmissionOilLeakage}
                      onChange={(e) =>
                        updateVehicleState(
                          "transmissionOilLeakage",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="No Leakage">No Leakage</option>
                      <option value="Leakage">Leakage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_transmissionoilleakageimage &&
                      images.iss_transmissionoilleakageimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iss_transmissionoilleakageimage
                            );
                            setImageId("iss_transmissionoilleakageimage");
                            getImages("iss_transmissionoilleakageimage");
                          }}
                        >
                          {images.iss_transmissionoilleakageimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_transmissionoilleakageimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_transmissionoilleakageimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iss_transmissionoilleakageimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_transmissionoilleakageimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iss_transmissionoilleakageimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_transfercaseoilleakage"
                    className="form-label btntextSize text-black"
                  >
                    Transfer Case Oil Leakage
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_transfercaseoilleakage"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.transferOilLeakage}
                      onChange={(e) =>
                        updateVehicleState("transferOilLeakage", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="No Leakage">No Leakage</option>
                      <option value="Leakage">Leakage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_transferoilleakageimage &&
                      images.iss_transferoilleakageimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iss_transferoilleakageimage
                            );
                            setImageId("iss_transferoilleakageimage");
                            getImages("iss_transferoilleakageimage");
                          }}
                        >
                          {images.iss_transferoilleakageimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_transferoilleakageimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_transferoilleakageimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iss_transferoilleakageimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_transferoilleakageimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iss_transferoilleakageimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_coolantleakage"
                    className="form-label btntextSize text-black"
                  >
                    Coolant Leakage
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_coolantleakage"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.coolantOilLeakage}
                      onChange={(e) =>
                        updateVehicleState("coolantOilLeakage", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="No Leakage">No Leakage</option>
                      <option value="Leakage">Leakage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_coolantleakageimage &&
                      images.iss_coolantleakageimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_coolantleakageimage);
                            setImageId("iss_coolantleakageimage");
                            getImages("iss_coolantleakageimage");
                          }}
                        >
                          {images.iss_coolantleakageimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_coolantleakageimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_coolantleakageimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_coolantleakageimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_coolantleakageimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_coolantleakageimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_breakoilleakage"
                    className="form-label btntextSize text-black"
                  >
                    Brake Oil Leakage
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_breakoilleakage"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.brakeOilLeakage}
                      onChange={(e) =>
                        updateVehicleState("brakeOilLeakage", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="No Leakage">No Leakage</option>
                      <option value="Leakage">Leakage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_brakeoilleakageimage &&
                      images.iss_brakeoilleakageimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_brakeoilleakageimage);
                            setImageId("iss_brakeoilleakageimage");
                            getImages("iss_brakeoilleakageimage");
                          }}
                        >
                          {images.iss_brakeoilleakageimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_brakeoilleakageimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_brakeoilleakageimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_brakeoilleakageimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_brakeoilleakageimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_brakeoilleakageimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_powersteeringoilleakage"
                    className="form-label btntextSize text-black"
                  >
                    Power Steering Oil Leakage
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_powersteeringoilleakage"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.poerSteeringOilLeakage}
                      onChange={(e) =>
                        updateVehicleState(
                          "poerSteeringOilLeakage",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="No Leakage">No Leakage</option>
                      <option value="Leakage">Leakage</option>
                      {/* <option value="Complete and Clean">
                        Complete and Clean
                      </option>
                      <option value="Incomplete and Old">
                        Incomplete and Old
                      </option> */}
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_powersteeringoillevelimage &&
                      images.iss_powersteeringoillevelimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iss_powersteeringoillevelimage
                            );
                            setImageId("iss_powersteeringoillevelimage");
                            getImages("iss_powersteeringoillevelimage");
                          }}
                        >
                          {images.iss_powersteeringoillevelimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_powersteeringoillevelimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_powersteeringoillevelimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iss_powersteeringoillevelimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_powersteeringoillevelimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iss_powersteeringoillevelimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_engineblowmanualcheack"
                    className="form-label btntextSize text-black"
                  >
                    Engine Blow (Manual Check)
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_engineblowmanualcheack"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.engineBlowManualCheck}
                      onChange={(e) =>
                        updateVehicleState(
                          "engineBlowManualCheck",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Not Present">Not Present</option>
                      <option value="Present">Present</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_engineblowimage &&
                      images.iss_engineblowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_engineblowimage);
                            setImageId("iss_engineblowimage");
                            getImages("iss_engineblowimage");
                          }}
                        >
                          {images.iss_engineblowimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_engineblowimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_engineblowimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_engineblowimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_engineblowimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_engineblowimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_enginenoise"
                    className="form-label btntextSize text-black"
                  >
                    Engine Noise
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_enginenoise"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.engineNoise}
                      onChange={(e) =>
                        updateVehicleState("engineNoise", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="No Noise">No Noise</option>
                      <option value="Abnormal Noise">Abnormal Noise</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_enginenoiseimage &&
                      images.iss_enginenoiseimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_enginenoiseimage);
                            setImageId("iss_enginenoiseimage");
                            getImages("iss_enginenoiseimage");
                          }}
                        >
                          {images.iss_enginenoiseimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_enginenoiseimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_enginenoiseimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_enginenoiseimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_enginenoiseimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_enginenoiseimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_enginevibration"
                    className="form-label btntextSize text-black"
                  >
                    Engine Vibration
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_enginevibration"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.engineVibration}
                      onChange={(e) =>
                        updateVehicleState("engineVibration", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="No Vibration">No Vibration</option>
                      <option value="Vibration">Vibration</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_enginevibrationimage &&
                      images.iss_enginevibrationimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_enginevibrationimage);
                            setImageId("iss_enginevibrationimage");
                            getImages("iss_enginevibrationimage");
                          }}
                        >
                          {images.iss_enginevibrationimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_enginevibrationimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_enginevibrationimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_enginevibrationimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_enginevibrationimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_enginevibrationimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_exhaustsound"
                    className="form-label btntextSize text-black"
                  >
                    Exhaust Sound
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_exhaustsound"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.exhaustSound}
                      onChange={(e) =>
                        updateVehicleState("exhaustSound", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_exhaustsoundimage &&
                      images.iss_exhaustsoundimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_exhaustsoundimage);
                            setImageId("iss_exhaustsoundimage");
                            getImages("iss_exhaustsoundimage");
                          }}
                        >
                          {images.iss_exhaustsoundimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_exhaustsoundimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_exhaustsoundimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_exhaustsoundimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_exhaustsoundimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_exhaustsoundimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_suctionfan"
                    className="form-label btntextSize text-black"
                  >
                    Suction Fan
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_suctionfan"
                      className="form-select btntextSize"
                      id="field21"
                      aria-label="Default select example"
                      required
                      value={step5.suctionFan}
                      onChange={(e) =>
                        updateVehicleState("suctionFan", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_sunctionfanimage &&
                      images.iss_sunctionfanimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_sunctionfanimage);
                            setImageId("iss_sunctionfanimage");
                            getImages("iss_sunctionfanimage");
                          }}
                        >
                          {images.iss_sunctionfanimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_sunctionfanimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_sunctionfanimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_sunctionfanimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_sunctionfanimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_sunctionfanimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_charging"
                    className="form-label btntextSize text-black"
                  >
                    Charging
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_charging"
                      className="form-select btntextSize"
                      id="field21"
                      aria-label="Default select example"
                      required
                      value={step5.charging}
                      onChange={(e) =>
                        updateVehicleState("charging", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_chargingimage &&
                      images.iss_chargingimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_chargingimage);
                            setImageId("iss_chargingimage");
                            getImages("iss_chargingimage");
                          }}
                        >
                          {images.iss_chargingimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_chargingimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_chargingimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_chargingimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_chargingimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_chargingimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_alternatoroperation"
                    className="form-label btntextSize text-black"
                  >
                    Alternator Operation
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_alternatoroperation"
                      className="form-select btntextSize"
                      id="iss_alternatoroperation"
                      aria-label="Default select example"
                      required
                      value={step5.alternatorOperation}
                      onChange={(e) =>
                        updateVehicleState(
                          "alternatorOperation",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_alternate_optionimage &&
                      images.iss_alternate_optionimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iss_alternate_optionimage
                            );
                            setImageId("iss_alternate_optionimage");
                            getImages("iss_alternate_optionimage");
                          }}
                        >
                          {images.iss_alternate_optionimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_alternate_optionimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_alternate_optionimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_alternate_optionimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_alternate_optionimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_alternate_optionimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_enginemounts"
                    className="form-label btntextSize text-black"
                  >
                    Engine Mounts
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_enginemounts"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.engineMounts}
                      onChange={(e) =>
                        updateVehicleState("engineMounts", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_enginemountsimage &&
                      images.iss_enginemountsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_enginemountsimage);
                            setImageId("iss_enginemountsimage");
                            getImages("iss_enginemountsimage");
                          }}
                        >
                          {images.iss_enginemountsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_enginemountsimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_enginemountsimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_enginemountsimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_enginemountsimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_enginemountsimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_pulleysadjuster"
                    className="form-label btntextSize text-black"
                  >
                    Pulleys (Adjuster)
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_pulleysadjuster"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.pulleyAdjuster}
                      onChange={(e) =>
                        updateVehicleState("pulleyAdjuster", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_pulleysadjusteriimage &&
                      images.iss_pulleysadjusteriimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iss_pulleysadjusteriimage
                            );
                            setImageId("iss_pulleysadjusteriimage");
                            getImages("iss_pulleysadjusteriimage");
                          }}
                        >
                          {images.iss_pulleysadjusteriimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_pulleysadjusteriimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_pulleysadjusteriimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_pulleysadjusteriimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_pulleysadjusteriimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_pulleysadjusteriimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="iss_acfitted"
                    className="form-label btntextSize text-black"
                  >
                    AC Fitted
                  </label>
                  <div className="input-group">
                    <select
                      name="iss_acfitted"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step5.acFitted}
                      onChange={(e) =>
                        updateVehicleState("acFitted", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iss_acfittedimage &&
                      images.iss_acfittedimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iss_acfittedimage);
                            setImageId("iss_acfittedimage");
                            getImages("iss_acfittedimage");
                          }}
                        >
                          {images.iss_acfittedimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iss_acfittedimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_acfittedimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_acfittedimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iss_acfittedimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iss_acfittedimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-12 mt-3">
                <label
                  for="iss_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="iss_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center pt-2 pb-4">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Step3;
