import * as React from "react";
import "../../Styles/style.css";
// import OrdersNavigation from "./myorder-navigation";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
// import TableSortLabel from '@mui/material/TableSortLabel';
import baseUrl from "../../../api/ipconfig";
import { useEffect, useState } from "react";
import axios from "axios";
import UserDetails from "../../ads/userDetails";
import MyadsNavigation from "../../ads/myads-navigation";
import { AuthContext } from "../../../context/AuthContext";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const columns = [
  { id: "orderid", label: "Order ID", minWidth: 50 },
  {
    id: "fullName",
    label: "fullName",
    minWidth: 90,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "city", label: "City", minWidth: 90 },
  {
    id: "address",
    label: "Address",
    minWidth: 140,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "inspectionSlot",
    label: "Inspection Slot",
    minWidth: 200,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "carDetails",
    label: "Vehicle",
    minWidth: 70,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 120,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 120,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function BiddingPendingOrders() {

  const { user } = React.useContext(AuthContext);
  const token = localStorage.getItem("token");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [inspectionOrders, setInspectionOrders] = useState(null);
  // const [sortedData, setSortedData] = useState([]);
  // const [sortDirection, setSortDirection] = useState('asc');
  // const [sortColumn, setSortColumn] = useState('');

  const history = useHistory();

  useEffect(() => {
    const fetchInspectionOrders = async () => {
      try {

        const response = await axios.get(
          `${baseUrl}/myinspectionrequest`,
          {
            params: {
              user_id: user?.id,
              is_auction: 1,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //console.log(response.data, "inspection orders ====");
        setInspectionOrders(response.data?.initial);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching user posts:", error);
        // setLoading(false);
      }
    };

    fetchInspectionOrders();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handlePendingOrders = () => {
  //   return inspectionOrders && inspectionOrders.length;
  // };

  // useEffect(() => {
  //   sortData();

  // }, []);

  // const sortData = () => {
  //   if (sortColumn && inspectionOrders.length > 0) {
  //     const sorted = [...inspectionOrders].sort((a, b) => {
  //       const valueA = a[sortColumn];
  //       const valueB = b[sortColumn];

  //       if (typeof valueA === 'string' && typeof valueB === 'string') {
  //         return valueA.localeCompare(valueB);
  //       } else {
  //         return valueA - valueB;
  //       }
  //     });

  //     setSortedData(sortDirection === 'asc' ? sorted : sorted.reverse());
  //   } else {
  //     setSortedData(inspectionOrders);
  //   }
  // };

  // const handleSort = (column) => {
  //   if (column === sortColumn) {
  //     setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  //   } else {
  //     setSortColumn(column);
  //     setSortDirection('asc');
  //   }
  // };

  const handleButtonClick = async (Id) => {
    history.push(`/inspector-form?inspection=${Id}`);
    try {
      const formData = new FormData();

      // Append form field values
      formData.append("user_id", user?.id);
      formData.append("inspection_id", Id);

      const response = await axios.post(
        `${baseUrl}/startinspection`,
        formData,{
          headers:{
            Authorization:`Bearer ${token}`
          },
          
            params:{
              is_online:1
            }          
        }
      );

      //console.log(response.data, "inspection response");
    } catch (err) {
      console.error(err.response);
    }
  };

  return (
    <>
      <div className="MyAds_main">
        <UserDetails />
        <div className="container-md MyAds_body">
          <div className="row pb-5">
            <div className="col-lg-2  p-0">
              <MyadsNavigation />
            </div>
            <div className="col-lg-10">
              <div className="ms-0 adsSection">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer
                    className="inspectionOrders"
                    sx={{ minHeight: 440 }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                              className="bgSecondary color-white"
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inspectionOrders &&
                          inspectionOrders
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((order, index) => (
                              <TableRow className="bg-light" key={index}>
                                <TableCell>{`#${order.inspection_id}`}</TableCell>
                                <TableCell>{order.full_name}</TableCell>
                                <TableCell>{order.city_name}</TableCell>
                                <TableCell>{order.address}</TableCell>
                                <TableCell>{order.inspection_slot}</TableCell>
                                <TableCell>{`${order.make_name} ${order.model_name} ${order.year_name}`}</TableCell>
                                <TableCell>{order.phone}</TableCell>

                                <TableCell>
                                  {" "}
                                  <Button
                                    onClick={() =>
                                      handleButtonClick(
                                        `${order?.inspection_id}`
                                      )
                                    }
                                    className=" px-5 bgSecondary color-white align-items-center "
                                    variant="contained"
                                  >
                                    Start
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 100]}
                    component="div"
                    count={inspectionOrders && inspectionOrders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="inspectionOrder_pagi"
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
