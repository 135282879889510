import * as React from "react";
import { useState, useEffect } from "react";
import "../Styles/style.css";
import axios from "axios";
import Profile from "../../images/profile.png";
import baseUrl from "../../api/ipconfig";
import BlueTick from "../../images/blue-tick-success.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#6d6d6d",
    color: "#ffffff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function UserDetails() {
  const [user, setUser] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(`${baseUrl}/getUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user posts:", error);
    }
  };
  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <>
      <div className="myUserDetail_Main bgUser_Detail pb-2 mb-md-3">
        <div className="container profileArea h-100 d-flex align-items-end">
          <img
            className="profilePic"
            src={Profile}
            alt="Profile Pic"
            loading="lazy"
          />
          <div className=" position-relative d-flex justify-content-center mb-2">
            <h4 className="pt-2 text-capitalize">{user && user?.name}</h4>
            {user?.role_id === 3 && (
              <Tooltip
                title="You are a member"
                arrow
                placement="top"
                enterDelay={40}
                leaveDelay={200}
              >
                <img
                  src={BlueTick}
                  className="membertick"
                  alt="Blue tick"
                  srcSet=""
                />
              </Tooltip>
            )}
          </div>
          {user?.role_id === 1 || user?.role_id === 2 ? (
            <HtmlTooltip
              arrow
              title={
                <React.Fragment>
                  Want to be a member?{" "}
                  <a
                    href="/become-a-member"
                    style={{ color: "#fff", borderBottom: "1px solid #fff" }}
                  >
                    {" "}
                    Join Us{" "}
                  </a>
                </React.Fragment>
              }
            >
              <p className="notMsg ms-2">Not a member</p>
            </HtmlTooltip>
          ) : (
            <></>
          )}
        </div>
        {/* <div className="d-flex align-items-center justify-content-center">
            <button className="btn myUserDetail_btn">Edit Profile</button>
            <div className="btnDivider"></div>
            <button className="btn myUserDetail_btn">Change Password</button>
          </div> */}
      </div>
    </>
  );
}
