import * as React from "react";
import "../Styles/style.css";
// import InspectionServices from "./inspectionServices";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addDays,
  startOfToday,
  setHours,
  setMinutes,
  isSameDay,
  isBefore,
  getHours,
  getMinutes,
} from "date-fns";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Modal, Box } from "@mui/material";
import LoginModal from "../loginModal/index.jsx";
import baseUrl from "../../api/ipconfig";
import InspectionReport from "../../images/inspection-report.png";
import BasicPkg from "../../images/basicPkg.png";
import StandardPkg from "../../images/standardPkg.png";
import PremiumPkg from "../../images/premiumPkg.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import InspectionServices from "./inspectionServices";
import InputMask from "react-input-mask";
import { useAuthContext } from "../../hooks/useAuthContext";
import { AuthContext } from "../../context/AuthContext";
import Credibility from "../../images/credibility.png";
import EasyReport from "../../images/easy-report.png";
import ExpertTechnician from "../../images/expert-technician.png";
import Relaxation from "../../images/relaxation.png";
import ReportImage from "../../images/sample-inspection-report.png";
import InspectionHeaderImage from "../../images/inspection-top-img.png";
import Car1 from "../../images/car1.png";
import Car2 from "../../images/car2.png";
import Car3 from "../../images/inspection-premium.png";
import LoadingModal from "../modals/loading-modal.jsx";

const successModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  minHeight: "10%",
  maxHeight: "95%",
  height: "auto",
  overflowY: "scroll",
};

export default function Inspection() {
  const { dispatch } = useAuthContext();
  // const [selectedDateTime, setSelectedDateTime] = useState(null);
  const { user } = React.useContext(AuthContext);

  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [city, setCity] = useState(null);
  const [vehicleType, setVehicleType] = useState("");
  const [address, setAddress] = useState("");
  const [inspectionSlot, setInspectionSlot] = useState(null);
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [cnic, setCnic] = useState(null);
  const [loading, setLoading] = useState(false);

  const [make, setMake] = useState(null);
  const [makeName, setMakeName] = useState(null);
  const [modelName, setModelName] = useState(null);
  const [yearName, setYearName] = useState(null);
  const [makeId, setMakeId] = useState("");
  const [modelId, setModelId] = useState("");
  const [YearId, setYearId] = useState("");

  const [modelYear, setModelYear] = useState(null);
  const [getModel, setGetModel] = useState(null);

  const [inspectionOrder, setInspectionOrder] = useState(null);

  // useEffect(() => {
  //   const replicafetchData = localStorage.getItem("data");
  //   const fetchData = JSON.parse(replicafetchData);

  //   setFullName(fetchData && fetchData.sub);
  //   setPhone(fetchData && fetchData.phone);
  // }, []);

  // useEffect(() => {
  //   let replicafetchData = localStorage.getItem("data");
  //   let fetchData = JSON.parse(replicafetchData);
  //   setUser(fetchData);
  // }, []);

  const [isOpen, setIsOpen] = useState(false);
  // const [user, setUser] = useState(null);

  const LoginOpen = () => {
    setIsOpen(true);
  };

  const LoginClose = () => {
    setIsOpen(false);
  };

  const history = useHistory();



  const inspectionSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const token = localStorage.getItem("token");
      const inspectionSlotLocal = inspectionSlot
        ? inspectionSlot.toLocaleString()
        : "";

      const formData = new FormData();

      // Append form field values
      formData.append("full_name", fullName);
      formData.append("phone", phone);
      formData.append("address", address);
      formData.append("cnic", cnic);
      formData.append("inspection_slot", inspectionSlotLocal);
      formData.append("user_id", user?.id || 0);
      formData.append("city_id", 1);
      formData.append("make_id", makeId);
      formData.append("model_id", modelId);
      formData.append("year_id", YearId);
      formData.append("request_type", user?.name);

      const response = await axios.post(
        `${baseUrl}/saveinspectionrequest`,
        formData
      );
      setOpenSuccessModal(true);

      setTimeout(() => {
        setOpenSuccessModal(false);
        if (user) {
          history.push("/inspection-orders");
        } else {
          history.push("/");
        }
      }, 2000);

      setInspectionOrder(response.data);
      // setSuccessPosted(true);
      //console.log(response.data, "inspection response");
    } catch (err) {
      setError(err.response);
    }finally{
      setLoading(false)
    }
  };

  const handleDateTimeChange = (date) => {
    //console.log("handleDateTimeChange called");
    //console.log("date:", date);

    const currentTime = new Date();
    const selectedTime = date || currentTime;

    if (
      isSameDay(selectedTime, currentTime) &&
      isBefore(selectedTime, currentTime)
    ) {
      const currentHour = getHours(currentTime);
      const currentMinute = getMinutes(currentTime);
      const updatedSlot = setHours(
        setMinutes(currentTime, currentMinute),
        currentHour
      );
      //console.log("updatedSlot:", updatedSlot);
      setInspectionSlot(updatedSlot);
    } else {
      setInspectionSlot(selectedTime);
    }
  };

  const minDate = startOfToday(); // Get the start of today's date
  const maxDate = addDays(minDate, 6); // Add one day to get tomorrow's date
  const minTime = setHours(setMinutes(new Date(), 0), 10); // Set minimum time to 10 AM
  const maxTime = setHours(setMinutes(new Date(), 0), 17); // Set maximum time to 5 PM
  const timeIntervals = 60; // Set the interval to 60 minutes

  const filterPassedTime = (time) => {
    const currentTime = new Date();
    const currentHour = getHours(currentTime);
    const currentMinute = getMinutes(currentTime);

    if (isSameDay(inspectionSlot, currentTime)) {
      return (
        getHours(time) > currentHour ||
        (getHours(time) === currentHour && getMinutes(time) >= currentMinute)
      );
    }

    return true;
  };

  const filterDate = (date) => {
    if (inspectionSlot && isSameDay(inspectionSlot, date)) {
      //console.log(inspectionSlot); // Check the value of inspectionSlot
      return getHours(inspectionSlot) <= 17;
    }
    return true;
  };

  const include = [
    {
      h4: "Engine",
      p: "Using a scanning tool plugged into the engine management system to identify any fault codes",
    },
    {
      h4: "Road Test",
      p: "Checks are done on brake operation, engine noise, exhaust emissions, steering/ suspension and more.",
    },
    {
      h4: "Exterior/Body Frame",
      p: "Visual inspection for rust, previous body repairs, panels and suspension.",
    },
    {
      h4: "Accident Damage",
      p: "A paint depth gauge test is used to check for previous cosmetic repairs. A visual inspection is used for evidence of accident.",
    },
    {
      h4: "Interior",
      p: "Checks are done on air conditioning, horn, seat belts, locks, warning lights and more.",
    },
    {
      h4: "Tyres Evaluation",
      p: "Checks are done on the tread depth of your vehicle tyres to make sure you have enough traction to grip the road.",
    },
  ];

  const packages = [
    {
      img: Car1,
      title: "Basic package",
      about: "Basic package",
      price: "PKR 2000",
    },
    {
      img: Car2,
      title: "Standard package",
      about: "1001cc - 2000cc",
      price: "PKR 4000",
    },
    {
      img: Car3,
      title: "Premium package",
      about: "Suv’s, 4x4, Jeeps and German Cars",
      price: "PKR 6000",
    },
  ];

  useEffect(() => {
    const fetchMake = async () => {
      try {
        const response = await axios.get(`${baseUrl}/byMake`);

        // //console.log("All Make", response.data);
        setMake(response.data);
        // setOptions(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMake();
  }, []);

  const handleMakeChange = (e) => {
    const selectedMake = make?.find((item) => item.makeName === e.target.value);
    if (selectedMake) {
      setMakeId(selectedMake.makeId);
    } else {
      setMakeId("");
    }
    setMakeName(e.target.value);
  };

  const handleModelChange = (e) => {
    const selectedModel = getModel.find(
      (item) => item.modelName === e.target.value
    );
    if (selectedModel) {
      setModelId(selectedModel.modelId);
    } else {
      setModelId("");
    }
    setModelName(e.target.value);
  };

  const handleYearChange = (e) => {
    const selectedYear = modelYear.find((item) => item.year === e.target.value);
    if (selectedYear) {
      setYearId(selectedYear.yearId);
    } else {
      setYearId("");
    }
    setYearName(e.target.value);
  };

  //console.log(modelName, "get model");

  // all models api

  useEffect(() => {
    const fetchModel = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/model-by-make?make_id=${makeId}`
        );

        //console.log("Model Details", response.data);
        setGetModel(response.data);
        // setOptions(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (makeId) {
      fetchModel();
    } else {
      setGetModel([]);
    }
  }, [makeId]);

  // all years api

  useEffect(() => {
    const fetchYear = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getModelYear`);

        //console.log("Model Year", response.data);
        setModelYear(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchYear();
  }, []);

  const onClose = () => {
    setIsOpen(false);
    setMake(null);
    setMakeName("");
    setModelName("");
    setYearName("");
    setMakeId("");
    setModelYear(null);
    setGetModel(null);
  };

  const handleConfirmVehicle = () => {
    setIsOpen(false);

    setVehicleType(`${makeName} ${modelName} ${yearName}`);
  };

  return (
    loading ? <>
    
<LoadingModal open={loading} onClose={()=>setLoading(false)}/>

    </> :
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-4">
          <div className="modalBody_area successBox  px-2 text-center">
            <h3 className="pt-2 mb-5">Select Your Vehicle</h3>

            <div className="container">
              <div className="col-12">
                <div className="mb-3 text-start">
                  <label for="vehicleMake" className="form-label">
                    Select Make
                  </label>
                  <select
                    className="form-select"
                    id="vehicleMake"
                    aria-label="Default select example"
                    required
                    value={makeName}
                    onChange={handleMakeChange}
                  >
                    <option selected value=" ">
                      Select Make
                    </option>
                    {make &&
                      make.map((item) => (
                        <option key={item.makeId} value={item.makeName}>
                          {item.makeName}
                        </option>
                      ))}
                  </select>
                  <div id="CityHelp" className="form-text">
                    Mention your vehicle's make (e.g. Honda)
                  </div>
                </div>
              </div>

              {makeName !== null && (
                <div className="col-12">
                  {makeId && getModel.length > 0 ? (
                    <div className="mb-3 text-start">
                      <label for="vehicleModel" className="form-label">
                        Model
                      </label>
                      <select
                        className="form-select"
                        id="vehicleModel"
                        aria-label="Default select example"
                        required
                        value={modelName}
                        onChange={handleModelChange}
                      >
                        <option selected value=" ">
                          Select {makeName && makeName} Model
                        </option>
                        {getModel &&
                          getModel.map((item) => (
                            <option key={item.modelId} value={item.modelName}>
                              {item.modelName}
                            </option>
                          ))}
                      </select>

                      <div id="CityHelp" className="form-text">
                        Mention your vehicle's Model (e.g. Civic)
                      </div>
                    </div>
                  ) : (
                    <div className="mb-3 text-start">
                      <label for="vehicleModel" className="form-label">
                        Model
                      </label>
                      <select
                        className="form-select border-danger"
                        id="vehicleModel"
                        aria-label="Default select example"
                        required
                        disabled
                      >
                        <option selected value=" ">
                          Select {makeName && makeName} Model
                        </option>
                      </select>

                      <div id="CityHelp" className="form-text text-danger">
                        Select make first
                      </div>
                    </div>
                  )}
                </div>
              )}
              {modelName && modelName !== "" && (
                <div className="col-12">
                  {makeId && modelName !== " " && modelYear.length > 0 ? (
                    <div className="mb-3 text-start">
                      <label for="vehicleYear" className="form-label">
                        Year
                      </label>
                      <select
                        className="form-select"
                        id="vehicleYear"
                        aria-label="Default select example"
                        required
                        value={yearName}
                        onChange={handleYearChange}
                      >
                        <option selected value=" ">
                          Select Year
                        </option>
                        {modelYear &&
                          modelYear.map((item) => (
                            <option key={item.yearId} value={item.year}>
                              {item.year}
                            </option>
                          ))}
                      </select>

                      <div id="CityHelp" className="form-text">
                        Mention your vehicle's Year (e.g. 2018)
                      </div>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <label for="vehicleYear" className="form-label">
                        Year
                      </label>
                      <select
                        className="form-select border-danger"
                        id="vehicleYear"
                        aria-label="Default select example"
                        required
                        disabled
                      >
                        <option selected value=" ">
                          Select Year
                        </option>
                      </select>
                      <div id="CityHelp" className="form-text text-danger">
                        Select Model first
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <Button
              onClick={handleConfirmVehicle}
              variant="contained"
              className="py-2 px-4 mt-3 color-white fw-700 bgSecondary  text-capitalize"
            >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>

      {/* <div className="inspectionBg">
        <div className="container h-100">
          <div className=" row h-100 ">
            <div className="col-lg-4 col-12 mb-4 mb-md-0">
              <div className="inspectionHead d-flex flex-column justify-content-center align-items-center align-items-md-start  text-center text-md-start  h-100">
                <h2 className="pb-3 fs-1  fw-800 ">
                  Get Fame Wheels Car inspection
                </h2>
                <p className="fs-5 fw-500">
                  Make a booking online for the car inspection service
                </p>
                <Link className="setSer" to="/car-inspection">
                  <Button
                    href="#appointment"
                    variant="contained"
                    className="bgSecondary text-white fw-700 px-5 py-2"
                  >
                    Get Service
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col-lg-8">
              <></>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid grad-bg90 py-0 ">
        <div className="container ">
          <div className="row align-items-center w-100">
            <div className="col-lg-6 col-12 text-center text-lg-start ">
              <h2 className="fw-bold color-white fs-1">
                Get Fame Wheels Car <br />
                <span className="fw-bold fs-1">Inspection</span>
                <p className="fs-6 fw-light mt-4">
                  Make a booking online for the car inspection service.
                </p>
              </h2>
            </div>
            <div className="col-lg-6 d-none d-lg-block col-12  text-end p-0">
              <img
                className="topBanner_img img-fluid "
                src={InspectionHeaderImage}
                alt="Sell through live bidding"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h3 className="pb-3 py-md-5 fw-title fw-700" id="appointment">
          Schedule Fame wheels <span>Car Inspection </span>
        </h3>
        {error && <p> {error} </p>}
        <form
          onSubmit={inspectionSubmit}
          className="inspectionForm postAdForm boxShadow p-4 mb-5 mx-3 mx-md-3 "
        >
          <div className="row mb-3">
            <div className="col-md-4 col-6">
              <div className="mb-md-3">
                <label for="inspectionCity" className="form-label">
                  Select City
                </label>

                {/* {user && user ? ( */}
                <select
                  className="form-select"
                  id="inspectionCity"
                  aria-label="Default select example"
                  required
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                >
                  <option value="">Select City</option>
                  <option value="Karachi">Karachi</option>
                  {/* <option value="Lahore">Lahore</option>
                    <option value="Islamabad">Islamabad</option>
                    <option value="Faisalabad">Faisalabad</option> */}
                </select>
                {/* // ) : (
                //   <>
                //     <select
                //       className="form-select"
                //       id="inspectionCity"
                //       aria-label="Default select example"
                //       required
                //       value={city}
                //       onChange={LoginOpen}
                //     >
                //       <option selected value=" ">
                //         Select City
                //       </option>
                //       <option value="Karachi">Karachi</option>
                //       <option value="Lahore">Lahore</option>
                //       <option value="Islamabad">Islamabad</option>
                //       <option value="Faisalabad">Faisalabad</option>
                //     </select>
                //     <LoginModal open={isOpen} onClose={LoginClose} />
                //   </>
                // )} */}
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div>
                <label for="inspectionName" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inspectionName"
                  placeholder="Enter Full Name"
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div>
                <label for="inspectionContact" className="form-label">
                  Phone Number
                </label>
                <InputMask
                  mask="03999999999"
                  maskChar={null}
                  type="text"
                  name="inspectionContact"
                  className="form-control"
                  id="inspectionContact"
                  placeholder="Phone No. (03xxxxxxxxx)"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="mb-md-3">
                <label for="inspectionAddress" className="form-label">
                  Complete Street Address
                </label>
                {/* {user && user ? ( */}
                <input
                  type="text"
                  className="form-control"
                  id="inspectionAddress"
                  aria-describedby="inspectionAddressHelp"
                  placeholder="House No/ Street/ Area"
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                {/* ) : ( */}
                {/* <input
                    type="text"
                    className="form-control"
                    id="inspectionAddress"
                    aria-describedby="inspectionAddressHelp"
                    placeholder="House No/ Street/ Area"
                    required
                    value={address}
                    onChange={LoginOpen}
                  /> */}
                {/* )} */}
                <div id="inspectionAddressHelp" className="form-text">
                  (e.g. House No 225,6Th Street, Cifton Karachi )
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6">
              <div className="mb-md-3">
                <label for="inspectionVehicle" className="form-label">
                  Make/Model/Year
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inspectionVehicle"
                  aria-describedby="inspectionVehicleHelp"
                  placeholder="Enter Make/Model/Year"
                  required
                  value={vehicleType}
                  onClick={() => setIsOpen(true)}
                  readOnly
                />
                <div id="inspectionVehicleHelp" className="form-text">
                  (e.g. Honda Civic RS 2022)
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="mb-1">
                <label for="vehicleYear" className="form-label">
                  Inspection Slot
                </label>
                <DatePicker
                  selected={inspectionSlot}
                  onChange={handleDateTimeChange}
                  showTimeSelect
                  timeFormat="h:mm aa"
                  dateFormat="dd-MM-yyy h:mm aa"
                  placeholderText="Select Inspection Slot"
                  className="form-control"
                  style={{ width: "100%" }}
                  minDate={minDate}
                  maxDate={maxDate}
                  minTime={minTime}
                  maxTime={maxTime}
                  timeIntervals={timeIntervals}
                  filterTime={filterPassedTime}
                  filterDate={filterDate}
                />
              </div>
            </div>

            {/* <div className="col-12">
              <label for="inspectionDetail" className="form-label">
                Any Message
              </label>
              <textarea
                className="form-control"
                id="inspectionDetail"
                aria-describedby="inspectionDetail"
                placeholder="Any Message (optional)"
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div> */}
          </div>
          <div className="text-center pt-3">
            {/* {user && user ? ( */}
            <button
              type="submit"
              className="btn bgSecondary text-white postFormBtn"
            >
              Book Inspection
            </button>
            {/* ) : ( */}
            {/* <button
                onClick={LoginOpen}
                className="btn fw-secondary postFormBtn"
              >
                Book Inspection
              </button> */}
            {/* )} */}
          </div>
        </form>
      </div>

      <div className="container py-5">
        <div className="memberFormHead">
          <h4 className="pb-4 color-black text-start fs-3  fw-700">
            Why Choose FameWheels car inspection?
          </h4>
        </div>

        <div className="row pb-5">
          <div className="col-lg-3 col-6  d-flex justify-content-center align-items-center ">
            <div className="StepperCount">01</div>
            <div className="OurSteppers">
              <img src={EasyReport} alt="" srcset="" />
              <h6 className="text-capitalize ">Easy Report</h6>
            </div>
          </div>

          <div className="col-lg-3 col-6 d-flex justify-content-center align-items-center ">
            <div className="StepperCount">02</div>
            <div className="OurSteppers">
              <img src={ExpertTechnician} alt="" srcset="" />
              <h6 className="text-capitalize ">Expert Technician</h6>
            </div>
          </div>

          <div className="col-lg-3 col-6 d-flex justify-content-center align-items-center ">
            <div className="StepperCount">03</div>
            <div className="OurSteppers">
              <img src={Relaxation} alt="" srcset="" />
              <h6 className="text-capitalize ">Relaxation</h6>
            </div>
          </div>

          <div className="col-lg-3 col-6 d-flex justify-content-center align-items-center ">
            <div className="StepperCount">04</div>
            <div className="OurSteppers">
              <img src={Credibility} alt="" srcset="" />
              <h6 className="text-capitalize ">Credibility</h6>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid grad-bg90 py-5 mb-5 ">
        <div className="container">
          <div className="row align-items-center justify-content-center w-100">
            <div className="col-lg-6 col-12 text-center text-lg-start mb-4 mb-lg-0">
              <h2 className="fw-bold color-white  fs-1 pb-3 ">
                Famewheels
                <p className="fs-1 fw-light mt-1">inspection report</p>
              </h2>
              <button
                type="submit"
                className="btn text-capitalize  bg-white color-secondary fw-700 text-white py-2 px-4 mt-2"
              >
                view sample report
              </button>
            </div>
            <div className="col-lg-5 col-md-12 col-12 text-center text-lg-end p-0">
              <img
                className="topBanner_img img-fluid "
                src={ReportImage}
                alt="Sell through live bidding"
              />
            </div>
          </div>
        </div>
      </div>

      {/* car pricing card  */}

      <div className="container py-5">
        <h4 className="pb-4 text-capitalize  color-black text-center text-lg-start fs-3 fw-700">
          FameWheels Vehicle Inspection Packages
        </h4>
        <div className="  d-flex justify-content-center justify-content-lg-between align-items-center gap-md-5 gap-2 flex-lg-nowrap flex-wrap ">
          {packages?.map((item) => (
            <div className=" boxShadow pt-5 inspection_card mb-4 position-relative rounded">
              <img src={item.img} alt="car-1-inspection" />

              <h4 className="mt-3">{item.title}</h4>
              <p className="color-secondary fw-semibold">{item.about}</p>

              <div className="w-100">
                <p className="mt-4 color-secondary fw-bold fs-5">
                  {item.price}
                </p>
              </div>

              <button
                className="btn position-absolute bottom-0 p-3 bgSecondary rounded-pill"
                style={{ right: -16 }}
              >
                <ArrowRightAltIcon sx={{ color: "white", fontSize: "2rem" }} />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* success modal */}

      <Modal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        disableAutoFocus={true}
      >
        <Box className="text-center successModal" sx={successModal}>
          <h3>Success!</h3>
          <h5>Your Inspection Slot Booked.</h5>

          <div className="text-center py-2">
            <h3>
              Your Order ID: {inspectionOrder && inspectionOrder.inspectionId}
            </h3>
          </div>
          <p>Our Representative will contact you shortly!</p>
          <p className="pt-1">Redirecting... </p>
        </Box>
      </Modal>

    
    </>
  );
}
