import React, { useState, useEffect } from "react";
import Homebody from "../components/home/index";
import PreLoader from "../components/loader";

export default function Homepage() {
  const [isLoading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Set loading state to false after 1 second
      setShowContent(true); // Show the content
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }

  if (!showContent) {
    return null;
  }

  return (
    <>
      <Homebody />
    </>
  );
}
