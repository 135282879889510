import * as React from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InnerLoader from "../loader/innerLoader";


const ImageModal = ({ image, onClose, open }) => {

  const [isLoading, setLoading] = React.useState(true);

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once the image is loaded
  };

  return (
    <>
    <Modal
    open={open}
        onClose={onClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
         <Box className="sm-modal p-3 p-md-4">
            <div className='modalBody_area'>
              <div onClick={onClose}>
                X
              </div>
            {isLoading && <InnerLoader />}
            <img src={image} alt="Uploaded Preview" style={{ width: '100%', display: isLoading ? 'none' : 'block' }} onLoad={handleImageLoad} />
            </div>
         </Box>
      </Modal>
    </>
  );
};

export default ImageModal;
