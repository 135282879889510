import * as React from "react";
import "../../Styles/style.css";
import baseUrl from "../../../api/ipconfig";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { Avatar } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import LoadingModal from "../../modals/loading-modal";

const columns = [
  { id: "orderid", label: "Order ID", minWidth: 50 },
  { id: "city", label: "City", minWidth: 70 },
  {
    id: "address",
    label: "Address",
    minWidth: 150,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "inspectionSlot",
    label: "Inspection Slot",
    minWidth: 150,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "carDetails",
    label: "Vehicle",
    minWidth: 150,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "fullname",
    label: "Full Name",
    minWidth: 70,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 70,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Start",
    minWidth: 140,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function Inspectionorders() {
  const { user } = React.useContext(AuthContext);

  const [orderType, setOrderType] = React.useState(0);
  const [tplOrders, settplOrders] = React.useState(null);
  const [inspectionOrders, setInspectionOrders] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [moved, setMoved] = useState([]);
  const [isMoved, seIsMoved] = useState(false);

  const fetchInspectionOrders = async () => {
    setIsSubmitting(true);
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(`${baseUrl}/inspectionrequest`, {
        params: {
          is_auction: orderType,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //console.log(response.data, "inspection orders ====");
      setInspectionOrders(response?.data?.initial);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching user posts:", error);
      // setLoading(false);
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (orderType === 0 || orderType === 1) {
      fetchInspectionOrders();
    }
  }, [orderType]);

  const fetchTplInspectionOrders = async () => {
    setIsSubmitting(true);
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(`${baseUrl}/tplleadlist`, {
        params: {
          tpllead_status: "Pending",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data?.data, "tpl inspection orders ====");
      settplOrders(response?.data?.data);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching user posts:", error);
      // setLoading(false);
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (orderType === 3) {
      fetchTplInspectionOrders();
    }
  }, [orderType]);

  console.log(orderType, "orderType");

  const history = useHistory();

  const handleTPLInspectionStart = async (Id, type) => {
    history.push(`/tpl-inspection-form?inspection=${Id}&vehicle=${type}`);
    try {
      const formData = new FormData();

      // Append form field values
      formData.append("user_id", user?.id);
      formData.append("inspection_id", Id);

      const response = await axios.post(
        `${baseUrl}/startinspection`,
        formData,
        {
          params: {
            is_online: 1,
          },
        }
      );

      //console.log(response.data, "inspection response");
    } catch (err) {
      console.error(err.response);
    }
  };

  const handleButtonClick = async (Id) => {
    history.push(`/inspector-online-form?inspection=${Id}`);
    try {
      const formData = new FormData();

      // Append form field values
      formData.append("user_id", user?.id);
      formData.append("inspection_id", Id);

      const response = await axios.post(
        `${baseUrl}/startinspection`,
        formData,
        {
          params: {
            is_online: 1,
          },
        }
      );

      //console.log(response.data, "inspection response");
    } catch (err) {
      console.error(err.response);
    }
  };

  const openWhatsApp = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber ? `92${phoneNumber.slice(1)}` : "";
    const whatsappUrl = `http://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodeURIComponent(
      "Hi,"
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handlePhone = (number) => {
    const phoneNumber = number;
    window.location.href = `tel:${phoneNumber}`;
  };

  const moveToOffline = async (order, Id) => {
    setOrderList([...orderList, order]);

    //console.log("order === >",order)

    localStorage.setItem(
      "inspectionOrdersList",
      JSON.stringify([...orderList, order])
    );

    try {
      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("inspection_id", Id);

      const response = await axios.post(
        `${baseUrl}/startinspection`,
        formData,
        {
          params: {
            is_online: 0,
          },
        }
      );

      //console.log(response.data, "inspection response");
    } catch (err) {
      console.error(err.response);
    }
  };

  useEffect(() => {
    //  const data = localStorage.getItem("inspectionOrdersIndex")
    // const allOrders = JSON.parse(data)
    // //console.log(allOrders)
    // if (allOrders) {
    //   setMoved(allOrders)
    // }
  }, []);

  const handleAddIndex = (index) => {
    // localStorage.setItem("inspectionOrdersIndex",JSON.stringify([...moved,index]))
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />
      <div className="MyAds_main py-5">
        <div className="container MyAds_body">
          <div className="row align-items-center pb-5">
            <div className="col-lg-6 col-12">
              <h3 className="pb-2 fw-700 text-center text-uppercase  fw-title">
                Inspection Orders
              </h3>
            </div>
            <div className="col-lg-6 col-12">
              <div className="d-flex justify-content-center justify-content-md-end align-content-center text-center text-sm-center text-lg-start">
                <div>
                  <label for="vehicleMake" className="form-label fw-600 me-2">
                    Order Type:
                  </label>
                  <select
                    className="form-select d-inline w-auto biddingDropDwon fw-500"
                    id="vehicleMake"
                    aria-label="Default select example"
                    required
                    value={orderType}
                    onChange={(e) => setOrderType(Number(e.target.value))}
                  >
                    <option value="0">Normal Inspection</option>
                    <option value="1">Bidding Inspections</option>
                    <option value="3">TPL Inspections</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {inspectionOrders &&
              inspectionOrders?.map((order, index) => {
                return (
                  <div
                    key={order.inspection_id}
                    className="card col-lg-6 px-3 py-2 mb-2"
                  >
                    <h4
                      className="d-flex justify-content-end "
                      style={{ fontSize: "10px" }}
                    >
                      <i className="fa-regular fa-clock me-1"></i>{" "}
                      {order?.inspection_slot}
                    </h4>
                    <div className="row h-100">
                      <div className="col-10 d-flex flex-column justify-content-between align-items-center justify-content-between">
                        <div className="d-flex w-100 justify-content-start">
                          <section className="col-6 inspection_Bio">
                            <div className="d-flex gap-2 justify-content-start">
                              <Avatar src="/broken-image.jpg" />

                              <div className="inspection_Bio d-flex flex-column gap-0 justify-content-center">
                                <h4 className="m-0">{order.full_name}</h4>
                                <p className="m-0">{order.city_name}</p>
                              </div>
                            </div>
                          </section>

                          <section className=" inspection_Order">
                            <p className="fw-bold">
                              {order.make_name} {order.model_name}{" "}
                              {order.yearName || order.year_name}
                            </p>
                          </section>
                        </div>

                        <div
                          className="d-flex justify-content-start w-100 align-items-center gap-2 my-1"
                          style={{ fontSize: "14px" }}
                        >
                          <p className="m-0">Address </p>{" "}
                          <p className=" fw-bold m-0"> {order.address}</p>
                        </div>
                      </div>

                      <section className="col-2">
                        <div className="d-flex flex-column align-items-end h-100 gap-1">
                          <button
                            // href={`tel:${order.phone}`}
                            onClick={() => handlePhone(order.phone)}
                            className="d-flex justify-content-center align-items-center inpection_order_btn bg-primary rounded-2 fs-5 text-white border-0 h-100"
                          >
                            <i className="fa-solid fa-phone"></i>
                          </button>
                          <button
                            onClick={() => openWhatsApp(order.phone)}
                            className="d-flex justify-content-center align-items-center inpection_order_btn bg-success text-white rounded-2 fs-5 border-0 h-100"
                          >
                            <i className="fa-brands fa-whatsapp"></i>
                          </button>
                        </div>
                      </section>
                    </div>

                    <Button
                      onClick={() =>
                        handleButtonClick(`${order?.inspection_id}`)
                      }
                      variant="contained"
                      className="py-1 mt-2 px-4 color-white fw-700 bgSecondary  text-capitalize"
                    >
                      Start
                    </Button>

                    <Button
                      onClick={() => {
                        moveToOffline(order, order?.inspection_id);
                        seIsMoved(true);
                        setMoved([...moved, +index]);
                        handleAddIndex(index);
                      }}
                      variant="contained"
                      color={moved?.includes(index) ? "inherit" : "primary"}
                      className="py-1 mt-2 px-4  fw-700 text-capitalize"
                      disabled={moved?.includes(index) ? true : false}
                    >
                      {moved?.includes(index) ? "moved" : "Move To Offline"}
                    </Button>
                  </div>
                );
              })}

            {orderType === 3 &&
              tplOrders &&
              tplOrders?.map((order, index) => {
                return (
                  <div
                    key={order.inspection_id}
                    className="card col-lg-6 px-3 py-2 mb-2"
                  >
                    <h4
                      className="d-flex justify-content-end "
                      style={{ fontSize: "10px" }}
                    >
                      <i className="fa-regular fa-clock me-1"></i>{" "}
                      {order?.tpllead_slot}
                    </h4>
                    <div className="row h-100">
                      <div className="col-10 d-flex flex-column justify-content-between align-items-center justify-content-between">
                        <div className="d-flex w-100 justify-content-start">
                          <section className="col-6 inspection_Bio">
                            <div className="d-flex gap-2 justify-content-start">
                              <Avatar src="/broken-image.jpg" />

                              <div className="inspection_Bio d-flex flex-column gap-0 justify-content-center">
                                <h4 className="m-0">{order.tpllead_name}</h4>
                                <p className="m-0">{order.city}</p>
                              </div>
                            </div>
                          </section>

                          <section className=" inspection_Order">
                            <p className="fw-bold">
                              {order.make} {order.model_name} {order.year}
                            </p>
                          </section>
                        </div>

                        <div
                          className="d-flex justify-content-start w-100 align-items-center gap-2 my-1"
                          style={{ fontSize: "14px" }}
                        >
                          <p className="m-0">Address </p>{" "}
                          <p className=" fw-bold m-0">
                            {" "}
                            {order.tpllead_address}
                          </p>
                        </div>
                      </div>

                      <section className="col-2">
                        <div className="d-flex flex-column align-items-end h-100 gap-1">
                          <button
                            // href={`tel:${order.phone}`}
                            onClick={() => handlePhone(order.tpllead_phone)}
                            className="d-flex justify-content-center align-items-center inpection_order_btn bg-primary rounded-2 fs-5 text-white border-0 h-100"
                          >
                            <i className="fa-solid fa-phone"></i>
                          </button>
                          <button
                            onClick={() => openWhatsApp(order.tpllead_phone)}
                            className="d-flex justify-content-center align-items-center inpection_order_btn bg-success text-white rounded-2 fs-5 border-0 h-100"
                          >
                            <i className="fa-brands fa-whatsapp"></i>
                          </button>
                        </div>
                      </section>
                    </div>

                    <Button
                      onClick={() =>
                        handleTPLInspectionStart(
                          `${order?.tpllead_id}`,
                          `${order?.is_car}`
                        )
                      }
                      variant="contained"
                      className="py-1 mt-2 px-4 color-white fw-700 bgSecondary  text-capitalize"
                    >
                      Start
                    </Button>
                    {/* 
                    <Button
                      onClick={() => {
                        moveToOffline(order, order?.inspection_id);
                        seIsMoved(true);
                        setMoved([...moved, +index]);
                        handleAddIndex(index);
                      }}
                      variant="contained"
                      color={moved?.includes(index) ? "inherit" : "primary"}
                      className="py-1 mt-2 px-4  fw-700 text-capitalize"
                      disabled={moved?.includes(index) ? true : false}
                    >
                      {moved?.includes(index) ? "moved" : "Move To Offline"}
                    </Button> */}
                  </div>
                );
              })}
            {inspectionOrders?.length <= 0 && tplOrders?.length <= 0 && (
              <p className="fs-4 text-center mt-4 text-danger fw-bold">
                No Inspection Orders Available
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
