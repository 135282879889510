import React, { useRef, useEffect, useState } from "react";

import "../../Styles/style.css";
import Button from "@mui/material/Button";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { Form } from "react-bootstrap";
import baseUrl, { baseStepsUrl } from "../../../api/ipconfig";
import InnerLoader from "../../loader/innerLoader";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import s3 from "../../../config/s3Config";

const Step4 = ({ onSuccess, previouse, userData, step8, setStep8 }) => {
  const history = useHistory();

  const token = localStorage.getItem("token");

  const formRef = useRef(null);
  const btnRef = useRef(null);

  const [uploadingImages, setUploadingImages] = useState([]);
  const [uploadingImagesIndex, setUploadingImagesIndex] = useState([]);
  const [imagePart, setImagePart] = useState(null);

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [successIsOpen, setSuccessIsOpen] = React.useState(false);

  const [payment, setPayment] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [openPayment, setOpenPayment] = useState(false);
  const [securityDeposit, setsSecurityDeposit] = useState(null);
  const [paymentToken, setsPaymentToken] = useState("");
  const [paymentStatus, setsPaymentStatus] = useState("");
  const [isAuction, setIsAuction] = useState(null);

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);
  const [isTestDrive, setIsTestDrive] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [prevImg, setPrevImg] = useState(null);
  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  const getImages = async (fieldName) => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    setImageLoading(true);
    setImagePart(fieldName);
    try {
      // Define the prefix based on the folder structure
      const prefix = `public/inspection/${StepToken}/${fieldName}/`;

      // List objects in the specified folder
      const params = {
        Bucket: "famewheels", // Replace with your bucket name
        Prefix: prefix,
      };

      const response = await s3.listObjectsV2(params).promise();

      // Construct image URLs from the S3 objects
      const imageUrls = response.Contents.map((item) => {
        return item;
      });

      // Update your state with the retrieved images
      setImages((prevImages) => ({
        ...prevImages,
        [fieldName]: imageUrls,
      }));

      setGalleryImages(imageUrls);

      // Optionally, if you want to store the image path separately
      setImagePath(prefix);
    } catch (error) {
      console.error("Error fetching images from S3:", error);
    } finally {
      setImageLoading(false);
    }
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    if (files.length > 0) {
      try {
        // Set pending status
        setUploadingImages([...uploadingImages, fieldName]);
        setUploadingImagesIndex([...uploadingImagesIndex, fieldName]);
        setPendingData("Image uploading");

        const uploadPromises = files.map(async (file) => {
          const options = {
            maxSizeMB: 0.06,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);

          const compressedImageFile = new File([compressedFile], file.name, {
            type: file.type,
          });

          // Dynamic folder creation
          // const folderName = StepToken;
          // const childFolderName = fieldName;
          const s3Key = `public/inspection/${StepToken}/${fieldName}/${compressedImageFile.name}`;

          const params = {
            Bucket: "famewheels",
            Key: s3Key,
            Body: compressedImageFile,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: compressedImageFile.type,
          };

          const response = await s3.upload(params).promise();

          const url = response.Key;

          const ImageName = url.substring(url.lastIndexOf("/") + 1);

          const formData = new FormData();
          formData.append("image_name", fieldName);
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 8);
          formData.append(`file`, ImageName);

          const responseApi = await axios.post(
            `${baseUrl}/saveinspectionimage`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Call getImages for each uploaded image (assuming getImages updates the UI)
          await getImages(fieldName);

          // Update the uploadingImages state after successful upload
          setUploadingImages((prevUploadImages) =>
            prevUploadImages.filter((item) => item !== fieldName)
          );
          setUploadingImagesIndex((prevUploadImages) =>
            prevUploadImages.filter((item) => item !== fieldName)
          );

          return response;
        });

        // Wait for all uploadPromises to resolve
        await Promise.all(uploadPromises);

        // Show success message
        setSuccessOpen(true);
        setSuccess("Images uploaded successfully");
      } catch (error) {
        console.error("Error uploading images:", error);
        setErrOpen(true);
        setError("Couldn't upload image, try again");
        setUploadingImages([]);
        setUploadingImagesIndex([]);
      } finally {
        // Reset pending status
        setPending(false);
      }
    }
  };

  const handleDeleteImage = async (ind, key) => {
    try {
      setPending(true);
      setPendingData("Deleting Image");

      // Debug: Log the key to ensure it's being passed correctly
      console.log("Deleting image with key:", key);

      const fieldName = key.split("/")[3];

      // Validate that the key is provided
      if (!key) {
        throw new Error("Image key is required for deletion");
      }

      // S3 deletion
      await s3
        .deleteObject({
          Bucket: "famewheels", // Replace with your bucket name
          Key: key, // Use the key provided as an argument
        })
        .promise();

      // Update state
      const updatedGalleryImages = [...galleryImages];
      updatedGalleryImages.splice(ind, 1); // Remove the deleted image from the array
      setGalleryImages(updatedGalleryImages);

      await getImages(fieldName);

      setSuccessOpen(true);
      setSuccess("Image Deleted Successfully");
    } catch (error) {
      console.error("Delete image error:", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      setPending(false);
    }
  };

  const handleImageGallery = (images) => {
    setOpenImageGallery(true);
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 8);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const url_id = window.location.search;
  const inspectionId = extractTokenFromUrl(url_id, "inspection");

  useEffect(() => {
    const getInpectionId = async () => {
      try {
        const response = await axios.get(`${baseUrl}/inspectionrequestdetail`, {
          params: {
            inspection_id: inspectionId,
          },
        });

        setsPaymentStatus(response?.data?.vehicledetail?.payment_status);
        setIsAuction(response?.data?.vehicledetail?.is_auction);
      } catch (error) {
        console.error(error);
      }
    };

    getInpectionId();
  }, [inspectionId]);

  const url = window.location.search;
  const StepToken = extractTokenFromUrl(url, "token");

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();

      setIsSubmitting(true);
      const url = window.location.search;
      const StepToken = extractTokenFromUrl(url, "token");
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("user_id", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
          // params.data.append("is_edit", 0);
          try {
            const response = await axios.post(
              `${baseUrl}/saveinteriorinspection`,
              params?.data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response?.data) {
              setSuccessOpen(true);
              setSuccess("Step 8 Completed");
            }
            //console.log("====================================");
            //console.log(response);
            setIsSubmitting(false);

            if (isAuction !== 1) {
              if (paymentStatus !== "paid") {
                setOpenPayment(true);
              } else {
                setSuccessIsOpen(true);

                setTimeout(() => {
                  setSuccessIsOpen(false);
                  window.open(
                    `https://inspectionreport.famewheels.com/inspection-report/${StepToken}`
                  );
                  history.push(`/inspection-history`);
                }, 4000);
              }
            } else {
              setSuccessIsOpen(true);

              setTimeout(() => {
                setSuccessIsOpen(false);
                window.open(
                  `https://inspectionreport.famewheels.com/inspection-report/${StepToken}`
                );
                history.push(`/bidding-inspection-reports`);
              }, 4000);
            }

            //console.log("====================================");
            formRef.current.hasUnsavedChanges = false;
          } catch (e) {
            console.error(e);
            setErrOpen(true);
            setError(`Step 8 Submit Failed!`);
            setIsSubmitting(false);
            return;
          } finally {
            btnRef.current?.setLoader(false);
          }
        }
        // onSuccess();
      };

      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 8");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [
      onSuccess,
      previouse?.iinitial_id,
      previouse?.iinitial_token,
      token,
      userData?.id,
    ]
  );

  const getImageByValue = (value) => {
    // Find the item with the specified value
    const item = uploadingImages.find((item) => item === value);

    //console.log("item image ===== >",item)

    // Return the found item
    return item;
  };

  const hidePendingImage = (imageName) => {
    const updatedImages = uploadingImagesIndex.filter(
      (item) => item !== imageName
    );
    // Update the state with the new array
    setUploadingImagesIndex(updatedImages);
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const formData = new FormData();

    formData.append("payment_status", payment);
    formData.append("payment_method", paymentMethod);
    formData.append("security_deposit", securityDeposit);
    formData.append("payment_token", paymentToken);
    formData.append("inspection_id", inspectionId);

    try {
      const response = await axios.post(
        `${baseUrl}/inspection-payment`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccessIsOpen(true);

      if (response?.status === 200) {
        setTimeout(() => {
          setSuccessIsOpen(false);
          window.open(
            `https://inspectionreport.famewheels.com/inspection-report/${StepToken}`
          );
          history.push(`/inspection-history`);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateCarPart = (field, value) => {
    setStep8((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        autoHideDuration={15000}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      {uploadingImagesIndex?.map((img, index) => (
        <Snackbar
          key={index}
          open={true}
          // onClose={() => hidePendingImage(img)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ marginTop: `${index * 60}px` }}
        >
          <Alert
            onClose={() => hidePendingImage(img)}
            severity="info"
            style={{ width: "65%" }}
          >
            {`${pendingData}`}
          </Alert>
        </Snackbar>
      ))}

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row" key={ind}>
                  <div className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage">
                    <img
                      src={`https://famewheels.s3.eu-north-1.amazonaws.com/${img.Key}`}
                      alt={img.Key}
                      className="object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img.Key)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <Modal
        open={openPayment}
        onClose={() => {
          setOpenPayment(false);
        }}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="md-modal position-relative p-3 p-md-4">
          <form
            className="w-100 p-3 mt-4 rounded-4"
            onSubmit={handlePaymentSubmit}
          >
            <h4 className="textSecondary">Payment Information</h4>

            <hr className="text-black" />

            <div className="row">
              <div className="col-lg-6 mb-3">
                <label
                  for="payment_status"
                  className="form-label text-black btntextSize"
                >
                  Payment Recieved
                </label>
                <select
                  name="payment_status"
                  className="form-select btntextSize"
                  id="payment_status"
                  aria-label="Default select example"
                  required
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                >
                  <option selected value="">
                    Select Payment
                  </option>
                  <option value="paid">Paid</option>
                  <option value="un_paid">Un Paid</option>
                </select>
              </div>

              {payment === "paid" && (
                <>
                  <div className="col-lg-6 mb-3">
                    <label
                      for="security_deposit"
                      className="form-label text-black btntextSize"
                    >
                      Inspection Amount
                    </label>
                    {/* <select
                      name="security_deposit"
                      className="form-select btntextSize"
                      id="security_deposit"
                      aria-label="Default select example"
                      required
                      value={securityDeposit}
                      onChange={(e) => setsSecurityDeposit(e.target.value)}
                    >
                      <option selected value="">
                        Select Amount
                      </option>
                      <option value={2000}>2000</option>
                      <option value={4000}>4000</option>
                      <option value={6000}>6000</option>
                    </select> */}

                    <input
                      name="security_deposit"
                      type="number"
                      className="form-control p-2 w-100 btntextSize"
                      id="security_deposit"
                      aria-label="Default select example"
                      required
                      placeholder="Security Deposit"
                      value={securityDeposit}
                      onChange={(e) => setsSecurityDeposit(e.target.value)}
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label
                      for="payment_method"
                      className="form-label text-black btntextSize"
                    >
                      Payment Mehtod
                    </label>
                    <select
                      name="payment_method"
                      className="form-select btntextSize"
                      id="payment_method"
                      aria-label="Default select example"
                      required
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <option selected value="">
                        Select Payment Mehtod
                      </option>
                      <option value={"cash"}>Cash</option>
                      <option value={"online"}>Online</option>
                    </select>
                  </div>

                  {paymentMethod === "online" && (
                    <div className="col-lg-6 mb-3">
                      <label
                        for="payment_token"
                        className="form-label text-black btntextSize"
                      >
                        Transection Id
                      </label>
                      <input
                        name="payment_token"
                        type="text"
                        className="form-control p-2 w-100 btntextSize"
                        id="payment_token"
                        aria-describedby="titleHelp"
                        required
                        placeholder="Transaction Id"
                        value={paymentToken}
                        onChange={(e) => setsPaymentToken(e.target.value)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <Button
              type="submit"
              className="color-white LegendIcon bg p-2 text-capitalize"
              fullWidth
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ii_testdrive"
                    className="form-label btntextSize text-black"
                  >
                    Test Drive
                  </label>
                  <div className="input-group">
                    <select
                      name="ii_testdrive"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step8.testDrive}
                      onChange={(e) => {
                        setIsTestDrive(e.target.value);
                        updateCarPart("testDrive", e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ii_testdriveimage &&
                      images.ii_testdriveimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ii_testdriveimage);
                            setImageId("ii_testdriveimage");
                            getImages("ii_testdriveimage");
                          }}
                        >
                          {images.ii_testdriveimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("ii_testdriveimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ii_testdriveimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ii_testdriveimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ii_testdriveimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ii_testdriveimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {isTestDrive === "Yes" && (
                <>
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_acoperation"
                        className="form-label btntextSize text-black"
                      >
                        AC Operation (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_acoperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.acOperationalDriving}
                          onChange={(e) =>
                            updateCarPart(
                              "acOperationalDriving",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Aplicable">Not Aplicable</option>
                          <option value="Perfect">Perfect</option>
                          <option value="Unsatisfactory">Unsatisfactory</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_acoperationimage &&
                          images.ii_acoperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_acoperationimage);
                                setImageId("ii_acoperationimage");
                                getImages("ii_acoperationimage");
                              }}
                            >
                              {images.ii_acoperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_acoperationimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_acoperationimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_acoperationimage")
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_acoperationimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_acoperationimage")
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_heateroperation"
                        className="form-label btntextSize text-black"
                      >
                        Heater Operation (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_heateroperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.heaterOperation}
                          onChange={(e) =>
                            updateCarPart("heaterOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Aplicable">Not Aplicable</option>
                          <option value="Perfect">Perfect</option>
                          <option value="Unsatisfactory">Unsatisfactory</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_heateroperationimage &&
                          images.ii_heateroperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_heateroperationimage
                                );
                                setImageId("ii_heateroperationimage");
                                getImages("ii_heateroperationimage");
                              }}
                            >
                              {images.ii_heateroperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_heateroperationimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_heateroperationimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_heateroperationimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_heateroperationimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_heateroperationimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_enginepick"
                        className="form-label btntextSize text-black"
                      >
                        Engine Pick
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_enginepick"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.enginePick}
                          onChange={(e) =>
                            updateCarPart("enginePick", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="ok">Ok</option>
                          <option value="Not Ok">Not Ok</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_enginepickimage &&
                          images.ii_enginepickimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_enginepickimage);
                                setImageId("ii_enginepickimage");
                                getImages("ii_enginepickimage");
                              }}
                            >
                              {images.ii_enginepickimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_enginepickimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_enginepickimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_enginepickimage")
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_enginepickimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_enginepickimage")
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_clutchoperation"
                        className="form-label btntextSize text-black"
                      >
                        Clutch Operation (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_clutchoperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.clutchOperation}
                          onChange={(e) =>
                            updateCarPart("clutchOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Smooth">Smooth</option>
                          <option value="Hand or Free">Hand or Free</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_clutchoperationimage &&
                          images.ii_clutchoperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_clutchoperationimage
                                );
                                setImageId("ii_clutchoperationimage");
                                getImages("ii_clutchoperationimage");
                              }}
                            >
                              {images.ii_clutchoperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_clutchoperationimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_clutchoperationimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_clutchoperationimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_clutchoperationimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_clutchoperationimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_gearshiftingmanual"
                        className="form-label btntextSize text-black"
                      >
                        Gear Shifting (Manual)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_gearshiftingmanual"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.gearShiftingManual}
                          onChange={(e) =>
                            updateCarPart("gearShiftingManual", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Smooth">Smooth</option>
                          <option value="Hard">Hard</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_gearshiftingimage &&
                          images.ii_gearshiftingimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_gearshiftingimage);
                                setImageId("ii_gearshiftingimage");
                                getImages("ii_gearshiftingimage");
                              }}
                            >
                              {images.ii_gearshiftingimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_gearshiftingimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_gearshiftingimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_gearshiftingimage")
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_gearshiftingimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_gearshiftingimage")
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_gearshiftingauto"
                        className="form-label btntextSize text-black"
                      >
                        Gear Shifting (Automatic)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_gearshiftingauto"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.gearShiftingAutomatic}
                          onChange={(e) =>
                            updateCarPart(
                              "gearShiftingAutomatic",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Smooth">Smooth</option>
                          <option value="Jerk">Jerk</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_gearshiftingautoimage &&
                          images.ii_gearshiftingautoimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_gearshiftingautoimage
                                );
                                setImageId("ii_gearshiftingautoimage");
                                getImages("ii_gearshiftingautoimage");
                              }}
                            >
                              {images.ii_gearshiftingautoimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_gearshiftingautoimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_gearshiftingautoimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_gearshiftingautoimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_gearshiftingautoimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_gearshiftingautoimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_4X4shifting"
                        className="form-label btntextSize text-black"
                      >
                        4X4 Shifting
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_4X4shifting"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.shifting4x4}
                          onChange={(e) =>
                            updateCarPart("shifting4x4", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Working">Working</option>
                          <option value="Not Working">Not Working</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_4x4shiftingimage &&
                          images.ii_4x4shiftingimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_4x4shiftingimage);
                                setImageId("ii_4x4shiftingimage");
                                getImages("ii_4x4shiftingimage");
                              }}
                            >
                              {images.ii_4x4shiftingimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_4x4shiftingimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_4x4shiftingimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_4x4shiftingimage")
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_4x4shiftingimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_4x4shiftingimage")
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_driveshaft_noise"
                        className="form-label btntextSize text-black"
                      >
                        Drive Shaft Noise
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_driveshaft_noise"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.driveShaftNoise}
                          onChange={(e) =>
                            updateCarPart("driveShaftNoise", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                        </select>
                      </div>
                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_driveshaftnoiceimage &&
                          images.ii_driveshaftnoiceimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_driveshaftnoiceimage
                                );
                                setImageId("ii_driveshaftnoiceimage");
                                getImages("ii_driveshaftnoiceimage");
                              }}
                            >
                              {images.ii_driveshaftnoiceimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_driveshaftnoiceimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_driveshaftnoiceimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_driveshaftnoiceimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_driveshaftnoiceimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_driveshaftnoiceimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_frontpropellershaft"
                        className="form-label btntextSize text-black"
                      >
                        Front Propeller Shaft
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_frontpropellershaft"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.frontPropellerShaft}
                          onChange={(e) =>
                            updateCarPart("frontPropellerShaft", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                          <option value="Removed from car">
                            Removed from car
                          </option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_frontpropellershaftimage &&
                          images.ii_frontpropellershaftimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_frontpropellershaftimage
                                );
                                setImageId("ii_frontpropellershaftimage");
                                getImages("ii_frontpropellershaftimage");
                              }}
                            >
                              {images.ii_frontpropellershaftimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_frontpropellershaftimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_frontpropellershaftimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_frontpropellershaftimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_frontpropellershaftimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_frontpropellershaftimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_rear_propeller_shaft"
                        className="form-label btntextSize text-black"
                      >
                        Rear Propeller Shaft
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_rear_propeller_shaft"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.rearPropellerShaft}
                          onChange={(e) =>
                            updateCarPart("rearPropellerShaft", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                          <option value="Removed from car">
                            Removed from car
                          </option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_rear_propeller_shaftimage &&
                          images.ii_rear_propeller_shaftimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_rear_propeller_shaftimage
                                );
                                setImageId("ii_rear_propeller_shaftimage");
                                getImages("ii_rear_propeller_shaftimage");
                              }}
                            >
                              {images.ii_rear_propeller_shaftimage.length}{" "}
                              photos <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_rear_propeller_shaftimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_rear_propeller_shaftimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_rear_propeller_shaftimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_rear_propeller_shaftimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_rear_propeller_shaftimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_differentialnoise"
                        className="form-label btntextSize text-black"
                      >
                        Differential Noise
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_differentialnoise"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.differentialNoise}
                          onChange={(e) =>
                            updateCarPart("differentialNoise", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_differentialnoiseimage &&
                          images.ii_differentialnoiseimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_differentialnoiseimage
                                );
                                setImageId("ii_differentialnoiseimage");
                                getImages("ii_differentialnoiseimage");
                              }}
                            >
                              {images.ii_differentialnoiseimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_differentialnoiseimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_differentialnoiseimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_differentialnoiseimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_differentialnoiseimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_differentialnoiseimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_brakepedaloperation"
                        className="form-label btntextSize text-black"
                      >
                        Brake Pedal Operation
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_brakepedaloperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.brakePedalOperation}
                          onChange={(e) =>
                            updateCarPart("brakePedalOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Timely Response">
                            Timely Response
                          </option>
                          <option value="Untimely Response">
                            Untimely Response
                          </option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_brakepedaloperationimage &&
                          images.ii_brakepedaloperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_brakepedaloperationimage
                                );
                                setImageId("ii_brakepedaloperationimage");
                                getImages("ii_brakepedaloperationimage");
                              }}
                            >
                              {images.ii_brakepedaloperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_brakepedaloperationimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_brakepedaloperationimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_brakepedaloperationimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_brakepedaloperationimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_brakepedaloperationimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="field5"
                        className="form-label btntextSize text-black"
                      >
                        ABS Operation
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_absoperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.absOperation}
                          onChange={(e) =>
                            updateCarPart("absOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Timely Response">
                            Timely Response
                          </option>
                          <option value="Untimely Response">
                            Untimely Response
                          </option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_absoperationimage &&
                          images.ii_absoperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_absoperationimage);
                                setImageId("ii_absoperationimage");
                                getImages("ii_absoperationimage");
                              }}
                            >
                              {images.ii_absoperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_absoperationimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_absoperationimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_absoperationimage")
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_absoperationimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_absoperationimage")
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_steringoperation"
                        className="form-label btntextSize text-black"
                      >
                        Steering Operation (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_steringoperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.steeringOperation}
                          onChange={(e) =>
                            updateCarPart("steeringOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Smooth">Smooth</option>
                          <option value="Rough">Rough</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_steeringoperationimage &&
                          images.ii_steeringoperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_steeringoperationimage
                                );
                                setImageId("ii_steeringoperationimage");
                                getImages("ii_steeringoperationimage");
                              }}
                            >
                              {images.ii_steeringoperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_steeringoperationimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_steeringoperationimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_steeringoperationimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_steeringoperationimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_steeringoperationimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_steeringwheelplay"
                    className="form-label btntextSize text-black"
                  >
                    Steering Wheel Play
                  </label>
                  <div className="input-group">
                    <select
                      name="ii_steeringwheelplay"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step8.steeringWheelPlay}
                      onChange={(e) =>
                        updateCarPart("steeringWheelPlay", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Play">Play</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ii_steeringwheelplayimage &&
                      images.ii_steeringwheelplayimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ii_steeringwheelplayimage
                            );
                            setImageId("ii_steeringwheelplayimage");
                            getImages("ii_steeringwheelplayimage");
                          }}
                        >
                          {images.ii_steeringwheelplayimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("ii_steeringwheelplayimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ii_steeringwheelplayimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ii_steeringwheelplayimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="ii_steeringwheelplayimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "ii_steeringwheelplayimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {isTestDrive === "Yes" && (
                <>
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_steeringwheelalignment"
                        className="form-label btntextSize text-black"
                      >
                        Steering Wheel Alignment (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_steeringwheelalignment"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.steeringWheelAlignment}
                          onChange={(e) =>
                            updateCarPart(
                              "steeringWheelAlignment",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="Centered">Centered</option>
                          <option value="Not Centered">Not Centered</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_steeringalignimage &&
                          images.ii_steeringalignimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_steeringalignimage
                                );
                                setImageId("ii_steeringalignimage");
                                getImages("ii_steeringalignimage");
                              }}
                            >
                              {images.ii_steeringalignimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_steeringalignimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_steeringalignimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_steeringalignimage")
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_steeringalignimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_steeringalignimage")
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="field6"
                        className="form-label btntextSize text-black"
                      >
                        Front Suspension (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_frontsuspension"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.frontSuspension}
                          onChange={(e) =>
                            updateCarPart("frontSuspension", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_frontsuspensionimage &&
                          images.ii_frontsuspensionimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_frontsuspensionimage
                                );
                                setImageId("ii_frontsuspensionimage");
                                getImages("ii_frontsuspensionimage");
                              }}
                            >
                              {images.ii_frontsuspensionimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_frontsuspensionimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_frontsuspensionimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_frontsuspensionimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_frontsuspensionimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_frontsuspensionimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="field7"
                        className="form-label btntextSize text-black"
                      >
                        Rear Suspension (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_rearsuspension"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.rearSuspension}
                          onChange={(e) =>
                            updateCarPart("rearSuspension", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_rearssupensionimage &&
                          images.ii_rearssupensionimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_rearssupensionimage
                                );
                                setImageId("ii_rearssupensionimage");
                                getImages("ii_rearssupensionimage");
                              }}
                            >
                              {images.ii_rearssupensionimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_rearssupensionimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_rearssupensionimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_rearssupensionimage")
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_rearssupensionimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_rearssupensionimage")
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_speedometer"
                        className="form-label btntextSize text-black"
                      >
                        Speedometer (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_speedometer"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.speedoMeter}
                          onChange={(e) =>
                            updateCarPart("speedoMeter", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Working">Working</option>
                          <option value="Not Working">Not Working</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_speedometerimage &&
                          images.ii_speedometerimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_speedometerimage);
                                setImageId("ii_speedometerimage");
                                getImages("ii_speedometerimage");
                              }}
                            >
                              {images.ii_speedometerimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_speedometerimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_speedometerimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_speedometerimage")
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_speedometerimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(e, "ii_speedometerimage")
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_testdrivedoneby"
                        className="form-label btntextSize text-black"
                      >
                        Test Drive Done By
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_testdrivedoneby"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.testDriveBy}
                          onChange={(e) =>
                            updateCarPart("testDriveBy", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Seller">Seller</option>
                          <option value="Buyer">Buyer</option>
                          <option value="Technician">Technician</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_testdrivedonebyimage &&
                          images.ii_testdrivedonebyimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_testdrivedonebyimage
                                );
                                setImageId("ii_testdrivedonebyimage");
                                getImages("ii_testdrivedonebyimage");
                              }}
                            >
                              {images.ii_testdrivedonebyimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        {!getImageByValue("ii_testdrivedonebyimage") && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <Button
                              variant="text"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-camera"></i> Camera
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_testdrivedonebyimage"
                                accept="image/**"
                                capture="user"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_testdrivedonebyimage"
                                  )
                                }
                              />
                            </Button>

                            <Button
                              variant="contained"
                              component="label"
                              className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                            >
                              <span className="text-capitalize btntextSize">
                                <i className="fa-solid fa-image"></i> Gallery
                              </span>
                              <input
                                className="form-control visually-hidden"
                                id="ii_testdrivedonebyimage"
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                onChange={(e) =>
                                  handleImageChange(
                                    e,
                                    "ii_testdrivedonebyimage"
                                  )
                                }
                              />
                            </Button>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="col-12 mt-3">
                <label
                  for="iin_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="iin_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center pt-2 pb-4">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Finish
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Step4;
