import * as React from "react";
import "../Styles/style.css";
import { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../api/ipconfig";
import UserDetails from "../ads/userDetails";
import MyadsNavigation from "../ads/myads-navigation";
import { Paper } from "@mui/material";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Box } from "@mui/material";
import Lottie from "lottie-react";
import InReview from "../../images/doc-in-review.json";
import DocSubmitting from "../../images/doc-submitting.json";

const successModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  minHeight: "10%",
  maxHeight: "95%",
  height: "auto",
  overflowY: "scroll",
};

export default function ProfileSettings() {
  // const { userId } = useContext(UserContext);
  // const [posts, setPosts] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [fullName, setFullName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState(null);
  const [city, setCity] = useState(null);
  const [user, setUser] = useState(null);
  const [cnicNumber, setCnicNumber] = useState(null);
  const [cities, setCities] = useState(null);
  const [urlToken, setUrlToken] = useState(null);
  const [frontCnic, setFrontCninc] = useState(null);
  const [backCnic, setBackCninc] = useState(null);

  const [frontPicCnic, setFrontPicCninc] = useState(null);
  const [backPicCnic, setBackPicCninc] = useState(null);

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  const fetchUserPosts = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(`${baseUrl}/getUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPhone(response?.data?.phone);
      setEmail(response?.data?.email);
      setFullName(response?.data?.name);
      setUser(response?.data);
    } catch (error) {}
  };

  const fetchCity = async () => {
    try {
      const response = await axios.get(`${baseUrl}/cities`);

      setCities(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCity();
    fetchUserPosts();
  }, []);

  const handleDateChange = (date) => {
    //console.log("handleDateChange called");
    //console.log("date:", date);

    const selectedTime = date;
    setDOB(selectedTime);
  };

  useEffect(() => {
    const handleUrlChange = () => {
      const url = window.location.href;
      const parts = url.split("?");
      setUrlToken(parts[1] || null);
    };

    handleUrlChange();

    const unlisten = history.listen(() => {
      handleUrlChange();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const UserSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");

      const formData = new FormData();

      formData.append("name", fullName);
      formData.append("address", city);
      formData.append("CNIC", cnicNumber);
      formData.append("phone", phone);
      formData.append("CNICFrontImage", frontPicCnic);
      formData.append("CNICBackImage", backPicCnic);

      const response = await axios.put(
        `${baseUrl}/fame/uploadDocuments/${user?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      history.push(`/`);
      //console.log(response.data, "inspection response");
    } catch (err) {
      console.error(err.response);
    }
  };

  const DocVerify = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const token = localStorage.getItem("token");

      const formData = new FormData();

      formData.append("front_cnic", frontPicCnic);
      formData.append("back_cnic", backPicCnic);
      formData.append("user_id", user?.id);

      const response = await axios.post(
        `http://146.71.76.22/famewheelsbackend/documentverification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(response.data, "doc verification");
      setOpenSuccessModal(true);
      setIsSubmitting(false);

      setTimeout(() => {
        setOpenSuccessModal(false);
        history.push("/profile");
      }, 6000);
    } catch (err) {
      console.error(err.response);
      setIsSubmitting(false);
    }
  };

  const frontCnicChange = (e) => {
    const file = e.target.files[0];
    setFrontPicCninc(file);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFrontCninc(imageUrl);
    }
  };

  const frontCnicDelete = () => {
    setFrontCninc(null);
  };

  const backCnicChange = (e) => {
    const file = e.target.files[0];
    setBackPicCninc(file);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setBackCninc(imageUrl);
    }
  };

  const backCnicDelete = () => {
    setBackCninc(null);
  };

  const token = localStorage.getItem("token");

  const goToVerify = () => {
    history.push(`/profile?${token}`);
  };

  return (
    <>
      {/* success modal */}

      <Modal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        disableAutoFocus={true}
      >
        <Box className="text-center successModal" sx={successModal}>
          <Lottie
            style={{ height: 250 }}
            animationData={InReview}
            loop={false}
          />
          <h3 className="fw-700">Verification In Process</h3>
          <p>Redirecting... </p>
        </Box>
      </Modal>

      {/* success modal */}
      <div className="MyAds_main">
        <UserDetails />
        <div className="container-md MyAds_body">
          <div className="row pb-5">
            <div className="col-lg-2  p-0">
              <MyadsNavigation />
            </div>
            <div className="col-lg-10 ">
              <div className="MyAdsArea_main ">
                <Paper
                  className="p-3"
                  sx={{ width: "100%", overflow: "hidden" }}
                >
                  <div className="container mt-3 pb-3">
                    <h4>My Profile</h4>
                    {user &&
                      user?.role_id !== 3 &&
                      user?.role_id !== 5 &&
                      !urlToken && (
                        <div className="wantBecome my-3 ">
                          {
                            user?.is_verified === 0 ? (
                              <div className="d-md-flex text-center text-md-start justify-content-between align-items-center">
                            <div>
                              <h4>Want to be a member?</h4>
                              <p>
                                If you want to become a member verify your
                                identity.
                              </p>
                            </div>
                            <div>
                              <button
                                onClick={goToVerify}
                                type="button"
                                className="btn bgWhite px-4 py-2 fw-600 mt-3 mt-md-0"
                                style={{ fontSize: 15 }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                            ) : user?.is_verified === 2 ? (
                              <div className="d-md-flex text-center text-md-start justify-content-between align-items-center">
                            <div>
                              <h4>Identity Verification Inprocess...</h4>
                              <p>
                               We will shortly respond to your application
                              </p>
                            </div>
                            <div>
                             
                            </div>
                          </div>
                            ) : null
                          }
                          
                        </div>
                      )}
                    <form className="postAdForm">
                      <div className="row mb-3">
                        <div className="col-6">
                          <label for="post-title" className="form-label">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="post-title"
                            aria-describedby="titleHelp"
                            required
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                        <div className="col-6">
                          <label for="post-title" className="form-label">
                            Nickname
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="post-title"
                            aria-describedby="titleHelp"
                            required
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-6">
                          <label for="post-title" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="post-title"
                            aria-describedby="titleHelp"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="col-6">
                          <label for="post-title" className="form-label">
                            Phone
                          </label>
                          <InputMask
                            mask="03999999999"
                            maskChar={null}
                            type="text"
                            className="form-control"
                            iid="post-phone"
                            placeholder="03xxxxxxxxx"
                            required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-6">
                          <label for="post-title" className="form-label">
                            Gender
                          </label>
                          <select
                            className="form-select"
                            id="post-registeration"
                            aria-label="Default select example"
                            required
                            placeholder="Gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option selected value="">
                              Select Gender
                            </option>
                            <option value="male">Male</option>
                            <option value="Female">Female</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                        <div className="col-6">
                          <label for="vehicleYear" className="form-label">
                            Date of Birth
                          </label>
                          <DatePicker
                            selected={dob}
                            onChange={handleDateChange}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select Date of Birth"
                            className="form-control"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-6">
                          <label for="post-title" className="form-label">
                            City
                          </label>
                          <select
                            className="form-select"
                            id="post-registeration"
                            aria-label="Default select example"
                            required
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          >
                            <option selected value="">
                              Select City
                            </option>
                            {cities &&
                              cities.map((item) => (
                                <option key={item.cityID} value={item.cityName}>
                                  {item.cityName}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-6">
                          <label for="post-title" className="form-label">
                            CNIC No.
                          </label>
                          <InputMask
                            mask="99999-9999999-9"
                            maskChar={null}
                            type="text"
                            className="form-control"
                            iid="post-phone"
                            placeholder="Enter Your Cnic No."
                            value={cnicNumber}
                            onChange={(e) => setCnicNumber(e.target.value)}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn bgSecondary color-white model_loginBTn my-3 px-4 py-1 fw-500 fs-5 "
                      // disabled={password !== confirmPassword}
                      onClick={UserSubmit}
                    >
                      Update
                    </button>
                  </div>
                </Paper>

                {/* document verification  */}

                {urlToken && (
                  <div className=" mt-3 mb-0 ">
                    <Paper
                      className="p-3"
                      sx={{ width: "100%", overflow: "hidden" }}
                    >
                      <div className="container mt-3 pt-2 pb-4">
                        <h4>Document Verification</h4>
                        {isSubmitting ? (
                          <div>
                            <Lottie
                              style={{ height: 300 }}
                              animationData={DocSubmitting}
                              loop
                            />
                          </div>
                        ) : (
                          <form className="postAdForm mb-0 pt-3">
                            <div className="row mb-3">
                              <div className="col-lg-6 col-12 text-center">
                                <label for="post-front" className="form-label">
                                  CNIC Front Image
                                </label>
                                {frontCnic ? (
                                  <div className="mt-2 ">
                                    <div className="uploaderPreview position-relative">
                                      <img src={frontCnic} alt="" srcSet="" />
                                      <button
                                        onClick={frontCnicDelete}
                                        className="cnicDelBtn"
                                      >
                                        <i
                                          className=" color-white fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="text-center mt-2">
                                    <Button
                                      variant="text"
                                      component="label"
                                      className="uploaderButtons bgFrontCnic p-0"
                                    >
                                      <p>+</p>
                                      <input
                                        name="cnic-front"
                                        className="form-control visually-hidden"
                                        id="cnic-front"
                                        accept=".jpg,.jpeg,.png"
                                        type="file"
                                        required
                                        onChange={frontCnicChange}
                                      />
                                    </Button>
                                  </div>
                                )}
                              </div>

                              <div className="col-lg-6 col-12 text-center">
                                <label for="cnic-back" className="form-label">
                                  CNIC Back Image
                                </label>
                                {backCnic ? (
                                  <div className="mt-2 ">
                                    <div className="uploaderPreview position-relative">
                                      <img src={backCnic} alt="" srcSet="" />
                                      <button
                                        onClick={backCnicDelete}
                                        className="cnicDelBtn"
                                      >
                                        <i
                                          className=" color-white fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="text-center mt-2">
                                    <Button
                                      variant="text"
                                      component="label"
                                      className="uploaderButtons bgBackCnic p-0"
                                    >
                                      <p>+</p>
                                      <input
                                        name="cnic-back"
                                        className="form-control visually-hidden"
                                        id="cnic-back"
                                        accept=".jpg,.jpeg,.png"
                                        type="file"
                                        required
                                        onChange={backCnicChange}
                                      />
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </form>
                        )}

                        <h5 className="fs-6 fw-600 mt-3">Note:</h5>
                        <ul className="cnicNote">
                          <li>
                            <b> CNIC </b> must match with your account name. Any
                            false information will reject your account
                            memebership.
                          </li>
                          <li>
                            Images must be cleared and readable otherwise your
                            request will cancel.
                          </li>
                          <li>
                            Your provided personal information is secured.
                          </li>
                          <li>
                            Your personal information and cnic are just used for
                            verification purpose.
                          </li>
                          <li>
                            Once verified you will not be able to edit your
                            profile information.
                          </li>
                        </ul>
                      </div>
                      <div className="text-center">
                        {frontCnic !== null || backCnic !== null ? (
                          <button
                            type="submit"
                            className="btn bgSecondary text-white model_loginBTn my-3 px-4 py-1 fw-500 fs-5 "
                            disabled={frontCnic === null || backCnic === null}
                            onClick={DocVerify}
                          >
                            Verify
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn  my-3 px-4 py-1 fw-500 fs-5 "
                            disabled={frontCnic === null || backCnic === null}
                            onClick={DocVerify}
                            style={{
                              backgroundColor: "#cfcfcf",
                              color: "#8f8f8f",
                              cursor: "no-drop",
                            }}
                          >
                            Verify
                          </button>
                        )}
                      </div>
                    </Paper>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
