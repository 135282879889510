import "../../Styles/style.css";
import Button from "@mui/material/Button";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import baseUrl from "../../../api/ipconfig";
import InputMask from "react-input-mask";
import ImageModal from "../imagePreviewModal";
import LoadingModal from "../../modals/loading-modal";
import PreLoader from "../../loader";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';import Form from "react-bootstrap/Form";


const Step1 = ({ onSuccess, APIResponse, user, userData,mileage,setMileage,engineNo,setEngineNo,chassisNo,setChassisNo,registeredNo,setRegisteredNo,confirmRegisteredNo,setConfirmRegisteredNo,driveType,setDriveType,engineType,setEngineType,cngInstall,setCngInstall,color,setColor,engineCapacity,setEngineCapacity,transmissionType,setTransmissionType,registeredCity,setRegisteredCity,registeredYear,setRegisteredYear}) => {
  const token = localStorage.getItem("token");
  const history = useHistory();

  const colorsData = {
    colors: [
      {
        name: "white",
        code: "#FFFFFF",
      },
      {
        name: "Silver",
        code: "#C0C0C0",
      },
      {
        name: "black",
        code: "#000000",
      },
      {
        name: "Grey",
        code: "#888888",
      },
      {
        name: "blue",
        code: "#000053",
      },
      {
        name: "green",
        code: "#0B780B",
      },
      {
        name: "red",
        code: "#FF0000",
      },
      {
        name: "gold",
        code: "#FFD700",
      },
      {
        name: "maroon",
        code: "#800000",
      },
      {
        name: "beige",
        code: "#F5F5DC",
      },
      {
        name: "pink",
        code: "#DA55AD",
      },
      {
        name: "brown",
        code: "#966938",
      },
      {
        name: "burgundy",
        code: "#6C1C1F",
      },
      {
        name: "yellow",
        code: "#FFFF00",
      },
      {
        name: "bronze",
        code: "#574327",
      },
      {
        name: "purple",
        code: "#57105C",
      },
      {
        name: "Turqouise",
        code: "#22EEE3",
      },
      {
        name: "orange",
        code: "#FE4F09",
      },
      {
        name: "indigo",
        code: "#3A125E",
      },
      {
        name: "magneta",
        code: "#65183A",
      },
      {
        name: "other",
        code: "#FFFFFF",
      },
    ],
  };

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // const [registeredNo, setRegisteredNo] = useState(null);
  // const [confirmRegisteredNo, setConfirmRegisteredNo] = useState(null);
  const [initial_id, setInitial_id] = useState(null);
  const [formToken, setFormToken] = useState(null);
  const [validRegistration, setValidRegistration] = useState(true);

  const [pending,setPending] = useState(false)
  const [pendingData,setPendingData] = useState('')
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  
  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const arr = new Uint8Array(raw.length);
    
    for (let i = 0; i < raw.length; i++) {
      arr[i] = raw.charCodeAt(i);
    }
    
    return new Blob([arr], { type: contentType });
  };
  
  const formRef = useRef(null);
  const btnRef = useRef(null);

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState(null);
  const [isSycc, setIsSync] = useState(null);
  
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    
    window.addEventListener("beforeunload", handleBeforeUnload);
    
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);
  
  useEffect(() => {
    const fetchId = async () => {

      setLoading(false)
    const newToken = uuidv4().replace(/-/g, "").slice(0, 12);
    //console.log("ind tok ====== > ",newToken)
      setFormToken(newToken)
      };
      
      fetchId();
    }, [token, user?.id]);
    
    const extractTokenFromUrl = (url, paramName) => {
      const urlSearchParams = new URLSearchParams(url);
      return urlSearchParams.get(paramName);
    };
    
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const inspectionId = extractTokenFromUrl(url, "inspection");
    const formId = extractTokenFromUrl(url, "form_id");
    const syncable = extractTokenFromUrl(url, "sync");
    
    useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    const fetchDraftPending = async () => {
      try {
        const response = await axios.get(`${baseUrl}/inspectionstepdetails`, {
          params: {
            iinitial_token: StepToken,
            inspectionstep_id: 1,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        //console.log(response?.data?.initial, "draft step 1");
        const formData = response?.data?.initial;
        setInitial_id(response?.data?.initial?.iinitial_id);

        populateFormWithResponse(formData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

if (isOnline === false) { 
  fetchDraftPending();
}
  }, [token]);

useEffect(()=>{
  setTimeout(() => {
    setIsSync(syncable)
  }, 2500);
},[syncable])

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }
  
    const formElements = formRef.current.elements;
  
    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];
  
      if (field) {
        field.value = formData[fieldName];
      }
    });
  };


  useEffect(()=>{

if (StepToken) {
  
    const formData = localStorage.getItem(`${StepToken}_first`);
    
  if (formData) {
     const newData = JSON.parse(formData);
      //console.log('formRef.current:', newData);
        setTimeout(() => {
          populateFormWithResponse(newData);
        }, 10);

          //console.log('Data retrieved from localStorage is not valid JSON:', formData);
  } else {
      console.error('No data found in localStorage with key "firstform"');
  }
}

},[StepToken])


 const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const now = new Date();
  // Apply the timezone offset to the current date to get Pakistan's date
  const pakistanTimezoneOffset = 5 * 60 + 0;
  const pakistanDate = new Date(
    now.getTime() + pakistanTimezoneOffset * 60 * 1000
  );
  const formattedDate = `${pakistanDate.getFullYear()}-${(
    pakistanDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${pakistanDate.getDate().toString().padStart(2, "0")}`;


const getInpectionId = async ()=>{

  const userData = JSON.parse(localStorage.getItem(`${StepToken}_userData`))

  setName(userData?.name)
  setPhone(userData?.phone)
  
}

useEffect(()=>{
  getInpectionId()
},[])

const cities =[
  {
      cityID: 1,
      cityName: "Karachi",
      cityimage: "Karachi.png"
  },
  {
      cityID: 2,
      cityName: "Faisalabad",
      cityimage: "Faislabad.png"
  },
  {
      cityID: 3,
      cityName: "Sukkur",
      cityimage: "Sukker.png"
  },
  {
      cityID: 4,
      cityName: "Sahiwal",
      cityimage: "Sahiwal.png"
  },
  {
      cityID: 5,
      cityName: "Lahore",
      cityimage: "Lahore.png"
  },
  {
      cityID: 6,
      cityName: "Islamabad",
      cityimage: "Islamabad.png"
  },
  {
      cityID: 7,
      cityName: "Bahawalpur",
      cityimage: "Bahawalpur.png"
  },
  {
      cityID: 8,
      cityName: "Hyderabad",
      cityimage: "Hyderabad.png"
  },
  {
      cityID: 9,
      cityName: "Multan",
      cityimage: "Multan.png"
  },
  {
      cityID: 10,
      cityName: "Quetta",
      cityimage: "Quetta.png"
  },
  {
      cityID: 11,
      cityName: "Peshwar",
      cityimage: "Peshwar.png"
  },
  {
      cityID: 12,
      cityName: "Sialkot",
      cityimage: "Sialkot.png"
  }
]

const years = [
  {
    yearId: 54,
    year: "2024"
},
  {
      yearId: 1,
      year: "2023"
  },
  {
      yearId: 2,
      year: "2022"
  },
  {
      yearId: 3,
      year: "2021"
  },
  {
      yearId: 4,
      year: "2020"
  },
  {
      yearId: 5,
      year: "2019"
  },
  {
      yearId: 6,
      year: "2018"
  },
  {
      yearId: 7,
      year: "2017"
  },
  {
      yearId: 8,
      year: "2016"
  },
  {
      yearId: 9,
      year: "2015"
  },
  {
      yearId: 10,
      year: "2014"
  },
  {
      yearId: 11,
      year: "2013"
  },
  {
      yearId: 12,
      year: "2012"
  },
  {
      yearId: 13,
      year: "2011"
  },
  {
      yearId: 14,
      year: "2010"
  },
  {
      yearId: 15,
      year: "2009"
  },
  {
      yearId: 16,
      year: "2008"
  },
  {
      yearId: 17,
      year: "2007"
  },
  {
      yearId: 18,
      year: "2006"
  },
  {
      yearId: 19,
      year: "2005"
  },
  {
      yearId: 20,
      year: "2004"
  },
  {
      yearId: 21,
      year: "2003"
  },
  {
      yearId: 22,
      year: "2002"
  },
  {
      yearId: 23,
      year: "2001"
  },
  {
      yearId: 24,
      year: "2000"
  },
  {
      yearId: 25,
      year: "1999"
  },
  {
      yearId: 26,
      year: "1998"
  },
  {
      yearId: 27,
      year: "1997"
  },
  {
      yearId: 28,
      year: "1996"
  },
  {
      yearId: 29,
      year: "1995"
  },
  {
      yearId: 30,
      year: "1994"
  },
  {
      yearId: 31,
      year: "1993"
  },
  {
      yearId: 32,
      year: "1992"
  },
  {
      yearId: 33,
      year: "1991"
  },
  {
      yearId: 34,
      year: "1990"
  },
  {
      yearId: 35,
      year: "1989"
  },
  {
      yearId: 36,
      year: "1988"
  },
  {
      yearId: 37,
      year: "1987"
  },
  {
      yearId: 38,
      year: "1986"
  },
  {
      yearId: 39,
      year: "1985"
  },
  {
      yearId: 40,
      year: "1984"
  },
  {
      yearId: 41,
      year: "1983"
  },
  {
      yearId: 42,
      year: "1982"
  },
  {
      yearId: 43,
      year: "1981"
  },
  {
      yearId: 44,
      year: "1980"
  },
  {
      yearId: 45,
      year: "1979"
  },
  {
      yearId: 46,
      year: "1978"
  },
  {
      yearId: 47,
      year: "1977"
  },
  {
      yearId: 48,
      year: "1976"
  },
  {
      yearId: 49,
      year: "1975"
  },
  {
      yearId: 50,
      year: "1974"
  },
  {
      yearId: 51,
      year: "1973"
  },
  {
      yearId: 52,
      year: "1972"
  },
  {
      yearId: 53,
      year: "1971"
  },

]

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      // setIsSubmitting(true);
      //console.log("initial_id", initial_id);

      const CreateUser = async () => {

        const formData = new FormData(formRef.current);

        // Convert formData to JSON object
        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });
        
        // Serialize form data to JSON string
        const serializedFormData = JSON.stringify(formDataObject);
        
        // Store serialized form data in localStorage

        localStorage.setItem(`${StepToken ? StepToken : formToken}_first`, serializedFormData);

        // for draft 
const numberId = parseInt(inspectionId)
// Retrieve existing data from localStorage

// Retrieve existing array from localStorage
let existingDrafts = localStorage.getItem('inspection_draft');

// Parse the retrieved string into a JavaScript object or initialize an empty array if it's null
existingDrafts = existingDrafts ? JSON.parse(existingDrafts) : [];

// Add a new object to the array
existingDrafts.push({name: formDataObject.iinitial_sellername, phone: formDataObject.iinitial_sellerno, token: StepToken, inspection_id: numberId, step: "1"});

// Stringify the updated array and set it back to localStorage
localStorage.setItem('inspection_draft', JSON.stringify(existingDrafts));

const ordersList = JSON.parse(localStorage.getItem('inspectionOrdersList'));
//console.log("Original ordersList:", ordersList);

const newOrdersList = ordersList.filter(order => order.inspection_id !== Number(inspectionId));
//console.log("Filtered newOrdersList:", newOrdersList);

localStorage.setItem("inspectionOrdersList", JSON.stringify(newOrdersList));

        // for draft end

        //console.log("newOrdersList === >>> " , newOrdersList)

        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("iinitial_token", StepToken ? StepToken : formToken);
          params.data.append("user_id", userData?.id);
          params.data.append("iinitial_date", formattedDate);
          // params.data.append("is_edit", StepToken ? 1 : 0);
          params.data.append(
            "iinitial_id",
            formId ? formId : initialData?.iinitial_id
          );
          params.data.append("inspection_id", inspectionId ? inspectionId : formId );

          // Object.entries(images).forEach(([fieldName, file]) => {
          //   params.data.append(fieldName, dataURLtoBlob(file));
          // });

history.push(`inspector-form?token=${StepToken ? StepToken : formToken}&&inspection=${inspectionId}`);

}
        onSuccess();
      };

      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true)
          setPendingData("Completing Step 1")
          CreateUser();
        } catch (e) {
          console.error(e);
          setIsSubmitting(false);
        } finally {
          setPending(false)
          btnRef.current?.setLoader(false);
          // setIsSubmitting(false);
        }
        btnRef.current?.setLoader(false);
      }
    
    },
    [
      APIResponse,
      formattedDate,
      formToken,
      onSuccess,
      token,
      user?.id,
    ]
  );

  const handleSave = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      //console.log("initial_id", initial_id);
      const url = window.location.search;
      const StepToken = extractTokenFromUrl(url, "token");
      const inspectionId = extractTokenFromUrl(url, "inspection");
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        
        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("iinitial_token", StepToken ? StepToken : formToken);
          params.data.append("user_id", userData?.id);
          params.data.append("iinitial_date", formattedDate);
          // params.data.append("is_edit", StepToken ? 1 : 0);

          params.data.append("iinitial_id", formId ? formId : initial_id);
          params.data.append("inspection_id", inspectionId);

          // Object.entries(images).forEach(([fieldName, file]) => {
          //   params.data.append(fieldName, dataURLtoBlob(file));
          // });
          try {
          
            const response = await axios.post(
              `${baseUrl}/saveinitialinspection`,
              params?.data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (response?.data) {
              setSuccessOpen(true)
            setSuccess("Step 1 Completed")
            }

            const formDataObject = {};
            params.data.forEach((value, key) => {
                formDataObject[key] = value;
            });
            

    
            // localStorage.removeItem(`${StepToken}_first`)

            APIResponse(response?.data);
            history.push(`inspector-form?token=${StepToken ? StepToken : formToken}&&inspection=${inspectionId}`);
            if (response) {
              setIsSubmitting(false);
            }
            formRef.current.hasUnsavedChanges = false;
          } catch (e) {
            console.error(e);
            setErrOpen(true)
            setError(`Step 1 Submit Failed!`)
            setIsSubmitting(false);
            return;
          } finally {
            btnRef.current?.setLoader(false);
          }
        }
        onSuccess();
      };

      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true)
          setPendingData("Completing Step 1")
          CreateUser();
        } catch (e) {
          console.error(e);
          setIsSubmitting(false);
        } finally {
          setPending(false)
          btnRef.current?.setLoader(false);
          // setIsSubmitting(false);
        }
        btnRef.current?.setLoader(false);
      }
    },
    [
      APIResponse,
      formattedDate,
      initialData?.iinitial_token,
      onSuccess,
      token,
      user?.id,
    ]
  );

useEffect(()=>{


  const handleSaveFields = async () => {
    // e.preventDefault();
    setIsSubmitting(true);
    //console.log("initial_id", initial_id);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const inspectionId = extractTokenFromUrl(url, "inspection");
    const formId = extractTokenFromUrl(url, "form_id");
    
    const CreateUser = async () => {
      
      if (formRef.current) {
        const params = {
          data: new FormData(formRef.current),
        };
        params.data.append("iinitial_token", StepToken ? StepToken : formToken);
        params.data.append("user_id", userData?.id);
        params.data.append("iinitial_date", formattedDate);
        // params.data.append("is_edit", StepToken ? 1 : 0);

        params.data.append("iinitial_id", formId ? formId : initial_id);
        params.data.append("inspection_id", inspectionId);

        // Object.entries(images).forEach(([fieldName, file]) => {
        //   params.data.append(fieldName, dataURLtoBlob(file));
        // });
        try {
        
          const response = await axios.post(
            `${baseUrl}/saveinitialinspection`,
            params?.data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response?.data) {
            setSuccessOpen(true)
          setSuccess("Step 1 Completed")
          }

          const formDataObject = {};
          params.data.forEach((value, key) => {
              formDataObject[key] = value;
          });
          

  
          // localStorage.removeItem(`${StepToken}_first`)

          APIResponse(response?.data);
      onSuccess();

          // history.push(`inspector-form?token=${StepToken ? StepToken : formToken}&&inspection=${inspectionId}${syncable ? "&sync=true":""}`);
          if (response) {
            setIsSubmitting(false);
          }
          formRef.current.hasUnsavedChanges = false;
        } catch (e) {
          console.error(e);
          setErrOpen(true)
          setError(`Step 1 Submit Failed!`)
          setIsSubmitting(false);
          return;
        } finally {
          btnRef.current?.setLoader(false);
        }
      }
    };

    if (formRef.current) {
      btnRef.current?.setLoader(true);
      try {
        setPending(true)
        setPendingData("Completing Step 1")
        CreateUser();
      } catch (e) {
        console.error(e);
        setIsSubmitting(false);
      } finally {
        setPending(false)
        btnRef.current?.setLoader(false);
        // setIsSubmitting(false);
      }
      btnRef.current?.setLoader(false);
    }
  }
    
    if (isSycc == "true") {
      //console.log("isSycc ==== ",isSycc)
      handleSaveFields()
    }

},[isSycc])


const handleRegistrationNumber = (e) => {
  const inputValue = e.target.value;
  const registrationRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{1,3}-\d{1,4}$/;

  setRegisteredNo(inputValue);
  if (inputValue.length <= 8 && registrationRegex.test(inputValue)) {
    //console.log("valid registration number");
    setValidRegistration(true)

  } else {
    //console.log("Invalid registration number");
    setValidRegistration(false)

  }
};

  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
          <LoadingModal
            open={isSubmitting}
            onClose={() => setIsSubmitting(false)}
          />
          <div className="container my-md-5">
            <div className="postInform p-5">
              <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
               
                <div className="row">
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize"
                    >
                      Customer Name
                    </label>
                    <input
                      name="iinitial_sellername"
                      type="text"
                      className="form-control btntextSize"
                      placeholder="Seller Name"
                      value={name}
                      onChange={(e)=>setName(e.target.value)}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Customer Phone Number
                    </label>
                    <input
                      type="number"
                      name="iinitial_sellerno"
                      className="form-control btntextSize"
                      placeholder="Phone No. (03xxxxxxxxx)"
                      value={phone}
                      onChange={(e)=>setPhone(e.target.value)}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleKM"
                      className="form-label text-black  btntextSize "
                    >
                      Mileage * (km)
                    </label>
                    <div className="input-group">
                      <input
                        name="iinitial_mileage"
                        type="number"
                        className="form-control btntextSize"
                        id="vehicleKM"
                        placeholder="Mileage"
                        min="0"
                        required
                        value={mileage}
                        onChange={(e) => setMileage(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize"
                    >
                      Engine No.
                    </label>
                    <div className="input-group">
                      <input
                        name="iinitial_engineno"
                        type="text"
                        className="form-control btntextSize"
                        id="post-title"
                        aria-describedby="titleHelp"
                        placeholder="Engine No."
                        value={engineNo}
                        onChange={(e) => setEngineNo(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Chassis No.
                    </label>
                    <div className="input-group">
                      <input
                        name="iinitial_chassisno"
                        type="text"
                        className="form-control btntextSize"
                        id="post-title"
                        aria-describedby="titleHelp"
                        required
                        placeholder="Chassis No."
                        value={chassisNo}
                        onChange={(e) => setChassisNo(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Registered No.
                    </label>
                    <input
                      name="iinitial_registeredno"
                      type="text"
                      className="form-control btntextSize text-uppercase"
                      required
                      placeholder="abc-1234"
                      maxLength={8}
                      value={registeredNo}
                      onChange={handleRegistrationNumber}
                    />
               

{!validRegistration && (
  <p
                        className="color-secondary mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        Should have Format of ABC-1234
                      </p>
) 

}




                  </div>



                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Confirm Registered No.
                    </label>
                    <input
                      type="text"
                      className="form-control btntextSize text-uppercase"
                      id="post-title"
                      aria-describedby="titleHelp"
                      required
                      placeholder="abc-1234"
                      value={confirmRegisteredNo}
                      onChange={(e) => setConfirmRegisteredNo(e.target.value)}
                    />
                    {registeredNo !== confirmRegisteredNo && (
                      <p
                        className="color-secondary mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        Registered No and Confirm Registered No Should be same
                      </p>
                    )}
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleDriveType"
                      className="form-label text-black  btntextSize "
                    >
                      Drive Type
                    </label>
                    <select
                      name="iinitial_drivetype"
                      className="form-select btntextSize"
                      id="vehicleDriveType"
                      aria-label="Default select example"
                      required
                        value={driveType}
                        onChange={(e) => setDriveType(e.target.value)}
                    >
                      <option selected value="">
                        Select Drive Type
                      </option>
                      <option value="2WD">2WD</option>
                      <option value="4WD">4WD</option>
                      <option value="AWD">AWD</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleEngineType"
                      className="form-label text-black  btntextSize "
                    >
                      Engine Type
                    </label>
                    <select
                      name="iinitial_enginetype"
                      className="form-select btntextSize"
                      id="vehicleEngineType"
                      aria-label="Default select example"
                      required
                      value={engineType}
                      onChange={(e) => setEngineType(e.target.value)}
                    >
                      <option selected value="">
                        Select Engine Type
                      </option>
                      <option value="Petrol">Petrol</option>
                      <option value="Diesel">Diesel</option>
                      <option value="Hybrid">Hybrid</option>
                      <option value="Electric">Electric</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleCNGInstall"
                      className="form-label text-black  btntextSize "
                    >
                      CNG Install
                    </label>
                    <select
                      name="iinitial_cnginstall"
                      className="form-select btntextSize"
                      id="vehicleCNGInstall"
                      aria-label="Default select example"
                      required
                      value={cngInstall}
                      onChange={(e) => setCngInstall(e.target.value)}
                    >
                      <option selected value="">
                        Select CNG Install
                      </option>
                      <option value="N/A">N/A</option>
                      <option value="Yes">Yes</option>
                      <option value="CNG Removed">CNG Removed</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="iinitial_color"
                      className="form-label text-black  btntextSize "
                    >
                      Exterior Colour
                    </label>

                    <select
                      name="iinitial_color"
                      className="form-select btntextSize"
                      id="iinitial_color"
                      aria-label="Default select example"
                      required
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    >
                      <option selected value="">
                        Select Color
                      </option>
                      {colorsData.colors?.map((color) => (
                        <option value={color.name}>{color.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Engine Capacity
                    </label>
                    <input
                      name="iinitial_enginecapacity"
                      type="number"
                      className="form-control btntextSize"
                      id="post-title"
                      aria-describedby="titleHelp"
                      required
                      placeholder="Engine Capacity"
                      value={engineCapacity}
                      onChange={(e) => setEngineCapacity(e.target.value)}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="vehicleTransmission"
                      className="form-label text-black  btntextSize"
                    >
                      Transmission Type
                    </label>
                    <select
                      name="iinitial_transmission"
                      className="form-select btntextSize"
                      id="vehicleTransmission"
                      aria-label="Default select example"
                      required
                      value={transmissionType}
                        onChange={(e) => setTransmissionType(e.target.value)}
                    >
                      <option selected value="">
                        Select Transmission
                      </option>
                      <option value="Automatic">Automatic</option>
                      <option value="Manual">Manual</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="field1"
                      className="form-label text-black  btntextSize "
                    >
                      Registered City
                    </label>
                    <select
                      name="city_id"
                      className="form-select btntextSize"
                      id="post-city"
                      aria-label="Default select example"
                      required
                      value={registeredCity}
                      onChange={(e) => setRegisteredCity(e.target.value)}
                    >
                      <option value="">Select City</option>
                      {cities &&
                        cities.map((item) => (
                          <option key={item?.cityID} value={item?.cityID}>
                            {item?.cityName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <label
                      for="post-year"
                      className="form-label text-black  btntextSize "
                    >
                      Registered Year
                    </label>
                    <select
                      name="year_id"
                      className="form-select btntextSize"
                      id="post-year"
                      aria-label="Default select example"
                      required
                      value={registeredYear}
                        onChange={(e) => setRegisteredYear(e.target.value)}
                    >
                      <option value="">Select Year</option>
                      {years &&
                        years.map((item) => (
                          <option key={item?.yearId} value={item?.yearId}>
                            {item?.year}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-12 mb-3">
                    <label
                      for="iinitial_comment"
                      className="form-label text-black  btntextSize "
                    >
                      Comment (Optional)
                    </label>

                    <Form.Control
                      as="textarea"
                      name="iinitial_comment"
                      placeholder="Leave a comment here"
                      style={{ height: "130px" }}
                      className="btntextSize"
                    />
                  </div>

                  <div className="text-center pt-2 pb-4 d-flex gap-3 justify-content-center align-items-center">
                    <Button
                      type="submit"
                      className=" px-5 bgSecondary color-white"
                      variant="contained"
                    >
                      Next
                    </Button>

                  {isSycc &&  <Button
                  type="button"
                  disabled={pending === true ? true : false}
                  className=" px-5 color-white"
                  variant="contained"
                  color="info"
                  onClick={handleSave}
                >
                  Save
                </Button>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Step1;
