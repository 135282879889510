import React from "react";
import ForgotPasswordBody from "../components/forgot-password";

export default function ForgotPassword() {
  return (
    <>
      <ForgotPasswordBody />
    </>
  );
}
