import * as React from "react";
import "../Styles/style.css";
import { useEffect, useState } from "react";
import axios from "axios";
// import baseUrl from "../../api/ipconfig";
import MyadsNavigation from "./myads-navigation";
import UserDetails from "./userDetails";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import InnerLoader from "../loader/innerLoader";
import baseUrl from "../../api/ipconfig";
import { AuthContext } from "../../context/AuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import LoadingModal from "../modals/loading-modal";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BiddingInspectionHistoryBody() {
  const { user } = React.useContext(AuthContext);

  const [inspectionHistory, setInspectionHistory] = useState([]);
  const [open, setOpen] = React.useState(false);
  // const [statusMsg, setStatusMsg] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [loadingEligible, setloadingEligible] = useState(false);
  const [nElModal, setNElModal] = useState(false);
  const [elModal, setElModal] = useState(false);
  const [reasonDesc, setReasonDesc] = useState(null);
  const [bIPostToken, setBIPosttoken] = useState(null);
  const [price, setPrice] = useState(" ");
  const [message, setMessage] = useState("");

  const ModalOpen = (BIToken) => {
    setNElModal(true);
    setBIPosttoken(BIToken);
  };

  const EligibleModalOpen = (BIToken) => {
    setElModal(true);
    setBIPosttoken(BIToken);
  };

  const ModalClose = () => {
    setNElModal(false);
    setElModal(false);
  };

  const handleBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // const user = localStorage.getItem("data");
  // const userId = JSON.parse(user);

  const fetchBiddingHistory = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseUrl}/finishinspectionlist`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userId: user?.id,
          is_auction: 1,
        },
      });
      setInspectionHistory(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching payment history", error);
    }
  };
  useEffect(() => {
    fetchBiddingHistory();
  }, []);

  const handleButtonClick = (token) => {
    window.open(
      `https://inspectionreport.famewheels.com/inspection-report/${token}`
    );
  };

  const EligibleNo = async (inspection_id) => {
    setloadingEligible(true);
    try {
      const token = localStorage.getItem("token");

      const formData = new FormData();

      // Append form field values
      formData.append("post_id", inspection_id);
      formData.append("auctionpoststatus_id", "-");
      formData.append("document_completed", "no");

      const response = await axios.post(
        `${baseUrl}/moveauctionpost`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNElModal(false);
      setOpen(true);
      setMessage("Status Updated Successfully");
      fetchBiddingHistory();

      setReasonDesc("");
    } catch (err) {
      console.error(err.response);
      setNElModal(false);
    } finally {
      setloadingEligible(false);
    }
  };

  const EligibleYes = async (inspection_id) => {
    setloadingEligible(true);
    try {
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("post_id", inspection_id);
      formData.append("auctionpoststatus_id", "-");
      formData.append("document_completed", "yes");

      const response = await axios.post(
        `${baseUrl}/moveauctionpost`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOpen(true);
      setElModal(false);
      setPrice("");
      setMessage("Status Updated Successfully");
      fetchBiddingHistory();
    } catch (err) {
      console.error(err.response);
      setElModal(false);
    } finally {
      setloadingEligible(false);
    }
  };

  const formatPrice = (price) => {
    if (price >= 100000000000) {
      return (
        (price / 100000000000).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }) + " Kharab"
      );
    } else if (price >= 1000000000) {
      return (
        (price / 1000000000).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }) + " Arab"
      );
    } else if (price >= 10000000) {
      return (
        (price / 10000000).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }) + " Crore"
      );
    } else if (price >= 100000) {
      return (
        (price / 100000).toLocaleString("en-US", { maximumFractionDigits: 2 }) +
        " lacs"
      );
    } else if (price >= 1000) {
      return (
        (price / 1000).toLocaleString("en-US", { maximumFractionDigits: 2 }) +
        " Thousand"
      );
    } else {
      return price.toLocaleString("en-US", { maximumFractionDigits: 2 });
    }
  };

  const fetchMakeModel = async () => {
    try {
      const response = await axios.get(`${baseUrl}/byMakeAndModel`);
      const makeModelData = response.data;
      // setMakes(makeModelData);
      console.log(makeModelData, "makeModelData");
    } catch (error) {
      console.error("Error fetching make model:", error);
    }
  };

  useEffect(() => {
    fetchMakeModel();
  }, []);

  return (
    <>
      <LoadingModal open={loadingEligible} />

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleBarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <div className="MyAds_main">
        <UserDetails />
        <div className="container-md MyAds_body">
          <div className="row pb-5">
            <div className="col-lg-2 p-0">
              <MyadsNavigation />
            </div>
            <div className="col-lg-10 ">
              {isLoading ? (
                <InnerLoader />
              ) : inspectionHistory?.initial?.length ? (
                <>
                  <div className=" bgSecondary boxShadow mb-3 d-none d-md-block">
                    <div className="row paymentHistory_header">
                      <div className="col-lg-3 col-2 ps-4">
                        <h6>Vehicle</h6>
                      </div>
                      <div className="col-lg-3 col-2 ps-4">
                        <h6>Registered No</h6>
                      </div>
                      {/* <div className="col-lg-3 col-3">
                        <h6>Registered No.</h6>
                      </div>
                      <div className="col-lg-2 col-2">
                        <h6>Location</h6>
                      </div> */}
                      <div className="col-lg-6 col-3 px-5 text-center d-flex justify-content-between align-items-center">
                        <h6>Action</h6>
                        <h6>Document Completed</h6>
                      </div>
                    </div>
                  </div>
                  {inspectionHistory?.initial?.map((item, index) => (
                    <div className=" bg-white paymentHistory_list boxShadow mb-3">
                      <div className="row align-items-center py-3 py-md-2  ms-2">
                        <div className="col-lg-3 col-6 text-center text-md-start   mb-2 ">
                          <h6>
                            {" "}
                            {item?.make_name} {item?.model_name}{" "}
                            {item?.year_name}
                          </h6>
                        </div>
                        <div className="col-lg-3 col-6 text-center text-md-start mb-2">
                          <h6>{item?.iinitial_registeredno}</h6>
                        </div>
                        <div className="col-lg-6 mt-3 col-11 rounded p-2 fs-6 text-white d-md-none text-center px-5 text-center d-flex justify-content-between align-items-center">
                          <h6 className="color-secondary fs-6 text-center w-100">
                            Document Completed
                          </h6>
                        </div>
                        <div className="col-lg-6 col-12 px-0 px-md-5 d-flex justify-content-between flex-column-reverse flex-md-row align-items-center  ">
                          <div className="text-center">
                            <Button
                              variant="contained"
                              className=" bgSecondary color-white me-3 checkReport_Btn"
                              onClick={() =>
                                handleButtonClick(item?.iinitial_token)
                              }
                            >
                              View
                            </Button>
                          </div>

                          <div className="text-center py-3 py-md-0">
                            {item?.document_completed === null ? (
                              <>
                                <div
                                  onClick={() =>
                                    EligibleYes(item?.inspection_id)
                                  }
                                  // onClick={() =>
                                  //   EligibleModalOpen(item?.iinitial_id)
                                  // }
                                  className="form-check form-check-inline "
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`eligibilityCheck${index}`}
                                    id={`yes${index}`}
                                  />
                                  <label
                                    className="form-check-label"
                                    for={`yes${index}`}
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div
                                  onClick={() =>
                                    EligibleNo(item?.inspection_id)
                                  }
                                  className="form-check form-check-inline"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`eligibilityCheck${index}`}
                                    id={`no${index}`}
                                  />
                                  <label
                                    className="form-check-label"
                                    for={`no${index}`}
                                  >
                                    No
                                  </label>
                                </div>
                              </>
                            ) : (
                              <div>
                                <h6>
                                  {item?.document_completed === "yes"
                                    ? "Completed"
                                    : "Not Completed"}
                                </h6>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="adsSection">
                  <div className="No_ads text-center">
                    <p>no history available</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={nElModal}
        onClose={ModalClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal p-3 p-md-4">
          <h6 className="fs-5 fw-bold ">Tell us reason!</h6>
          <textarea
            className="form-control"
            id="reason"
            aria-describedby="reason"
            required
            placeholder="Tell us reason..."
            rows="4"
            value={reasonDesc}
            onChange={(e) => setReasonDesc(e.target.value)}
          ></textarea>
          <div className="mt-3 text-end">
            <Button
              variant="contained"
              className=" bgSecondary color-white"
              style={{ textTransform: "unset" }}
              onClick={EligibleNo}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={elModal}
        onClose={ModalClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal p-3 p-md-4">
          <h6 className="fs-5 fw-bold ">Valuation Price:</h6>
          <input
            type="number"
            className="form-control"
            id="price"
            aria-describedby="price"
            required
            placeholder="Vehicle Valuation Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          {formatPrice(price)}
          <div className="mt-3 text-end">
            <Button
              variant="contained"
              className=" bgSecondary color-white"
              style={{ textTransform: "unset" }}
              onClick={EligibleYes}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
