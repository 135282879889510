import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

// export const getUserFromToken = async (token) => {
//   try {
//     const response = await axios.get('https://d6cd-2400-adc1-494-8400-6417-6faa-1fe8-ac4b.ngrok-free.app/fame/user', {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     });
//     return response.data;
//   } catch (err) {
//     console.error(err.response);
//     return null;
//   }
// };

export default setAuthToken;